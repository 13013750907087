import ResponsiveNavBarContainer from "../components/NavBar/ResponsiveNavBarContainer";
import IntroductionWithImage from "../components/IntroductionWithImage";
import MainImageCarousel from "../components/MainImageCarousel";
import CardCarousalContainer from "../components/CardCarousalContainer";
import StickyNavAndFooter from "./layout/StickyNavAndFooter";
import { mainPageIntroduction, missionCards, visionCards } from "../data/data";
import IEngg from "../components/IE";
import blossomintoinnerfreedom from '../resources/images/blossomintoinnerfreedom.png'
import { primaryColor } from "../config";
import { AbsoluteCenter, Box, Card, CardBody, Divider } from "@chakra-ui/react";

export default function Home() {
    return (
        <StickyNavAndFooter>
            <ResponsiveNavBarContainer />
            <MainImageCarousel />
            <Card>
                <CardBody>
                    <IntroductionWithImage img={blossomintoinnerfreedom} id={"main-introduction"}>
                        {mainPageIntroduction}
                    </IntroductionWithImage>
                </CardBody>
            </Card>

            <IEngg id={"ie-introduction"} />
            <Box id={"vision"}>
                <CardCarousalContainer cards={visionCards} containerTitle={"Vision"} />
            </Box>
            <br />
            <br />
            <Box id='mission'>
                <CardCarousalContainer cards={missionCards} containerTitle="Mission" />
            </Box>
        </StickyNavAndFooter>
    )
}