import IEExperience from "../components/IEExperience";
import LibraryBlog from "../components/LibraryBlog";
import MainBlog from "../components/MainBlog";
import SelfIntroduction from "../components/SelfIntroduction";
import NonStickyNavAndFooter from "./layout/NonStickyNavAndFooter";

export default function ExperiencesPage() {
    return (
        <NonStickyNavAndFooter>
           <IEExperience />
        </NonStickyNavAndFooter>
    )
}

