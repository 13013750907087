import { NavItem } from "./NavBarTypes.ts/NavItem";
import ResponsiveNavBar from "./ResponsiveNavBar";

export interface ResponsiveNavBarContainerProps_t {
    sticky?: boolean;
}
export default function ResponsiveNavBarContainer({ sticky = true }: ResponsiveNavBarContainerProps_t) {
    return <ResponsiveNavBar navItems={NAV_ITEMS} sticky={sticky} />
}

const NAV_ITEMS: Array<NavItem> = [
    { label: "Home", href: '/' },

    {
        label: 'Inspiration',
        children: [
            {
                label: 'Isha',
                subLabel: 'Conscious Planet, Save Soil, Rally For Rivers...',
                href: 'https://consciousplanet.org/en',
                target: "_blank"
            },
            {
                label: 'J KrishnaMurti',
                subLabel: 'To free the mind from all conditioning, you must see...',
                href: 'https://www.jkrishnamurti.org/',
                target: "_blank"
            },
        ],
    },
    {
        label: 'Learn *',
        children: [
            {
                label: 'Library',
                subLabel: 'Books are a great companion...',
                href: '/library',
            },
            // {
            //     label: 'Of Age Learning Center',
            //     subLabel: 'Does Learning ever end?',
            //     href: '/#',
            // },
            // {
            //     label: 'Software Engineering',
            //     subLabel: 'Intern, Volunteer',
            //     href: '/softwaresystems',
            // },
            // {
            //     label: 'Home School (Coming soon...)',
            //     subLabel: 'The way of life...',
            //     href: '/homeschool',
            // },
            // {
            //     label: 'Yoga',
            //     subLabel: 'Hatha Yoga, Dialogues, Coming Soon!',
            //     href: '#',
            // },

            // {
            //     label: 'Tutorials',
            //     subLabel: 'Academic Support',
            //     href: '#',
            // },
            // {
            //     label: 'Languages',
            //     subLabel: 'Coming Soon!',
            //     href: '#',
            // },
            // {
            //     label: 'Science & Arts',
            //     subLabel: 'Instruments, Dance & ...',
            //     href: '#',
            // },
            // {
            //     label: 'Essentials',
            //     subLabel: 'WoodWorking',
            //     href: '#',
            // },
            // {
            //     label: 'See more',
            //     subLabel: "Coming soon!",
            //     // 'Library, Tutorials, Science & Arts, Wood working'
            //     href: '#',
            // },
        ],
    },
    // {
    //     label: 'Join Us',
    //     href: '#',
    //     children: [
    //         {
    //             label: 'Club',
    //             subLabel: 'Children, Adult, Retired, we all can learn! Coming Soon',
    //             href: '#',
    //         },
    //         {
    //             label: 'Volunteer',
    //             subLabel: 'Be in touch with community; in other word Life! Coming Soon',
    //             href: '#',
    //         },
    //         {
    //             label: 'Upcoming Events',
    //             subLabel: 'Explore and Join us for a day! Coming Soon',
    //             href: '#',
    //         },
    //         {
    //             label: 'Join the cause',
    //             subLabel: 'What do you care about? Coming Soon',
    //             href: '#',
    //         },
    //     ],
    // },
    // {
    //     label: 'Give Back',
    //     href: '#',
    //     children: [
    //         {
    //             label: 'Our Services',
    //             // Event Management
    //             subLabel: 'What we do to align with our values... Coming Soon',
    //             href: '#',
    //         },
    //         {
    //             label: 'Donate',
    //             subLabel: 'Time or Money, Ideas or Resource, any difference? Coming Soon',
    //             href: '#',
    //         },
    //     ],
    // },
    {
        label: "People",
        children: [
            {
                label: "Sharath",
                subLabel: "DaReMo  <@ _  @>",
                href: '/about',
            }
        ]
    },
    {
        label: 'Blog',
        href: '#',
        children: [
            {
                label: 'SwaDharma Spandana',
                subLabel: 'What, Why, How, Who? Know Us!',
                href: '/blog',
            },
            {
                label: 'Our Experiences',
                subLabel: 'Events, News Letters Coming Soon',
                href: '/our-experiences',
            },
        ],
    }
]
