
import React from 'react';
import { PropsWithChildren } from 'react'
import { Box } from "@chakra-ui/react";
import { maxWidth } from "../../config";
import ResponsiveFooter from '../../components/Footer/ResponsiveFooter';

export default function StickyNavAndFooter({ children }: PropsWithChildren) {
    return (
        <>
            <Box className="nav-bar" position={"absolute"} minHeight={"5rem"}
                height={"10vh"} w="100vw" />
            <Box maxW={maxWidth}
                position={"relative"}
                margin={"auto"} >
                {children}
                <br />
                <br />
            </Box>
            <ResponsiveFooter />
        </>
    )
}
