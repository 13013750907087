import {
    Table,
    Thead,
    Tbody,
    // Tfoot,
    Tr,
    Th,
    Td,
    // TableCaption,
    TableContainer,    
    TableProps,
} from "@chakra-ui/react";


export interface DataTableProps_t extends TableProps {
    dataHeader: string[];
    dataBody: any;
    formatRow: Function;
    highlightRow: number;
}

export const GenericTableContainer = ({
    dataHeader,
    dataBody,
    formatRow,
    highlightRow,
    ...rest
}: DataTableProps_t) => {
    const header = dataHeader
    const rows = dataBody;

    

    return (
        <TableContainer width="98%" >
            <Table {...rest}  size="md" >
                <Thead>
                    <Tr cursor="pointer" key={0}>
                        {header.map((cell, idx) =>
                            <Th textAlign={"center"} key={idx}>{cell}</Th>
                        )}
                    </Tr>
                </Thead>
                <Tbody>
                    {rows.map((row: any, idx: number) => {
                        const key = idx + 1
                        const background = key == highlightRow ? "#ff5f00" : "";
                        return <Tr  key={key} background={background} >
                            {
                                formatRow(row, key).map((cell: string, idx: number) => {
                                    const breakWhiteSpace = (cell && cell.length > 150) ? "break-spaces" : "";
                                    return <Td key={idx} whiteSpace={breakWhiteSpace}>{
                                        cell ? cell : "Null"
                                    }</Td>
                                }
                                )}
                        </Tr>
                    })}
                </Tbody>
            </Table>
        </TableContainer>
    );
};
