import LibraryBlog from "../components/LibraryBlog";
import MainBlog from "../components/MainBlog";
import NonStickyNavAndFooter from "./layout/NonStickyNavAndFooter";

export default function BlogPage() {
    return (
        <NonStickyNavAndFooter>
            <MainBlog />
            <br />
            <LibraryBlog />
        </NonStickyNavAndFooter>
    )
}

