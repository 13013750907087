import {
    Box,
    Flex,
    IconButton,
    Collapse,
    useColorModeValue,
    useDisclosure,
    useBreakpointValue,
} from '@chakra-ui/react'
import {
    HamburgerIcon,
    CloseIcon,
} from '@chakra-ui/icons'
import DesktopNavBar from './DesktopNavBar';
import MobileNavBar from './MobileNavBat';
import { NavProps_t } from './NavBarTypes.ts/NavItem';
import { maxWidth } from '../../config';


export default function ResponsiveNavBar({ navItems, sticky }: NavProps_t) {
    // https://v2.chakra-ui.com/docs/styled-system/color-mode  // color - #cd6529
    const bg = useColorModeValue('#E9884f', '#BF4803');

    const stickyHeight = useBreakpointValue({base:"15vh", "2xl":"30vh"})

    return sticky ? (
        <Box position={"absolute"} zIndex={1} height={stickyHeight} m={0}>
            <Box position={"sticky"}
                zIndex={0}
                display={"block"}
                minHeight={"5rem"}
                height={"10vh"}
                width={{base:"100vW", "2xl":maxWidth}}
                top={0}
                left={0}
                className='nav-bar'>
                <NavBar navItems={navItems} />
            </Box>
        </Box >
    ) : <Box
        position={"relative"} zIndex={1}
        top={0}
        minHeight={"5rem"}
        height={"10vh"}
        width={{base:"100vW", "2xl":maxWidth}}
        className='nav-bar'><NavBar navItems={navItems} />
    </Box>
}


function NavBar({ navItems }: NavProps_t) {
    const { isOpen, onToggle } = useDisclosure()
    return (
        <>
            <Flex
                minH={'5rem'}
                h='10vh'
                py={{ base: 2 }}
                px={{ base: 10 }}
                borderColor={useColorModeValue('gray.200', 'gray.900')}
                align={'center'} 
                overflow={{sm:"scroll", lg:"hidden"}}
                sx={{
                    '&::-webkit-scrollbar': {
                      width: '0.1px',
                    }
                  }}
                >


                <Flex flex={{ base: 1 }} align={"center"} justifyContent={'space-between'} >
                    
                    <Box cursor={"pointer"} as="a" href='/' minW={"5rem"}>
                        <img src="https://upload.wikimedia.org/wikipedia/en/6/6b/Terrestrial_globe.svg" height={"90rem"} width={"90rem"} />
                    </Box>
                    <Flex display={{ base: 'none', md: 'flex' }} m={0}>
                        <DesktopNavBar navItems={navItems} />
                    </Flex>
                </Flex>


                <Flex
                    display={{ base: 'flex', md: 'none' }}
                    flex={{ base: 1, md: 'auto' }}
                    ml={{ base: -2 }}

                    justify={{ base: 'right' }}>
                    <IconButton
                        _hover={{
                            backgroundColor: "black",
                        }}
                        onClick={onToggle}
                        icon={isOpen ? <CloseIcon w={3} h={3} color={"white"}
                        /> : <HamburgerIcon
                            w={5} h={5} color={"white"}
                        />}
                        variant={'ghost'}
                        aria-label={'Toggle Navigation'}
                    />
                </Flex>


            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <MobileNavBar navItems={navItems} />
            </Collapse>
        </>
    )
}







