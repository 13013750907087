import { AbsoluteCenter, Divider, Box } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

interface DividerAbsoluteCenterProps_t {
    bg: string
    dividerBorderColor: string
}

export default function DividerAbsoluteCenter({ bg, dividerBorderColor, children }: PropsWithChildren<DividerAbsoluteCenterProps_t>) {
    return (
        <Box position='relative' padding='10'>
            <Divider borderColor={dividerBorderColor}></Divider>
            <AbsoluteCenter bg={bg} px='4'>
                {children}
            </AbsoluteCenter>
        </Box >
    )
}