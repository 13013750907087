import {
    Box, Card, CardHeader, Heading, CardBody, CardFooter, Button, Text,
    useBreakpointValue
} from "@chakra-ui/react";
import { primaryColor, primaryFontSize, secondaryColorTwo, secondaryFontSize, white } from "../../config";
import { ReactElement, useState } from "react";
import { CarouselCardDetails_t } from "./CarouselCard";

export interface CarouselCardBody {
    title: string;
    shortContent: string;
    content: string | ReactElement;
}


export interface navigatableDetails_t {
    href: string;
    title: string;
}

export interface CarouselCardProps_t {
    card: CarouselCardDetails_t;
    imageHeight: string;
    footerButtonHandleClick?: (id: string) => void;
}


export default function CarouselCardPost({ card, imageHeight, footerButtonHandleClick }: CarouselCardProps_t) {
    return (<Box key={card.id} display={"flex"} justifyContent={"center"} m={useBreakpointValue({ base: "2rem", lg: 0 })}>
        <Card width={{ base: "100%", md: "90vw" }}
            height={{ base: "40rem", md: "40rem" }}
            minW={{ base: "100%", lg: "20rem" }}
            maxW={{ base: "100%", md: "70vw" }}
            bg={white}
        >
            <CardHeader textAlign={"center"} pb={0} >
                <Heading size='md' color={primaryColor}> {card.title}</Heading>
            </CardHeader>
            <CardBody pt={primaryFontSize} pr={useBreakpointValue({ base: 0, md: "3rem" })} pl={useBreakpointValue({ base: 0, md: "3rem" })} h="40%" >
                <Box height={imageHeight} width={"full"} overflow={"hidden"}
                    pr={{ base: "0", md: primaryFontSize }}
                    pl={{ base: "0", md: primaryFontSize }}
                    backgroundImage={card.image}
                    backgroundSize={{ base: "12rem", md: "24rem" }}
                    backgroundPosition={"50%"}
                    backgroundRepeat={"no-repeat"}
                >
                </Box>
                <Box display="block" height={"15rem"}>
                    <Box p={"0.5rem"}>
                        <Heading textAlign="center" fontSize={secondaryFontSize} color={primaryColor}>{card.body.title}</Heading>
                        <br />
                        <Box overflow={"scroll"} height={"11rem"}>
                            <Text pr={"0.5rem"} pl={"0.5rem"} justifyContent={"center"} color={secondaryColorTwo} >
                                {card.body.shortContent}
                            </Text>
                        </Box>
                    </Box>
                </Box>
            </CardBody>
            <CardFooter justifyContent={"center"}>
                <Box display={"flex"} m="1rem" justifyContent={"space-between"}>
                {
                    footerButtonHandleClick ?
                        <Button bg={primaryColor} color={"white"} onClick={() => footerButtonHandleClick(card.groupId+"_"+card.id)}
                            _hover={{
                                backgroundColor: secondaryColorTwo,
                            }}
                        >{card.footer}</Button> :
                        <Button bg={primaryColor} color={"white"} as={"a"} href={card.href}
                            _hover={{
                                backgroundColor: secondaryColorTwo,
                            }}
                        >{card.footer}</Button>
                }
                </Box>
            </CardFooter>
        </Card>


    </Box>)
}


