import ResponsiveNavBarContainer from "../components/NavBar/ResponsiveNavBarContainer";
import SelfIntroduction from "../components/SelfIntroduction";
import NonStickyNavAndFooter from "./layout/NonStickyNavAndFooter";

export default function About() {
    return (
        <NonStickyNavAndFooter>
            <SelfIntroduction />
        </NonStickyNavAndFooter>
    )
}