import { Box, Button, Card, Heading, Text } from "@chakra-ui/react";
import { headingPrimaryFont, headingSecondaryFont, primaryColor, secondaryColorOne, secondaryColorTwo, secondaryFontSize, smallFontSize } from "../config";
import { Author, ItalicText } from "../components/CustomUtilComponents";
import coulditbeeasy from '../resources/images/coulditbeeasy.png'
import ultimatefreedom from '../resources/images/ultimatefreedom.png'
import reapwhatyousow from '../resources/images/reapwhatyousow.jpeg'
import savesoil from '../resources/images/savesoil.jpeg'
import spreadingjoy from '../resources/images/spreadingjoy.png'
import intelligence from '../resources/images/intelligence.png'
import me from '../resources/images/me.png'
import read from '../resources/images/read.jpeg'
import foodWastage from '../resources/images/foodWastage.jpg'
import suicideprevention from '../resources/images/suicideprevention.png'

const visionOneArticle = <>
    <Text color={"black"}>
        There's a lot of talk in the town drawing parallel between Avatars of Deity Vishnu from scriptures and Darwin’s evolutionary theory,
        are they one and the same? nobody can dictate one's opinion of this on another but if at all anyone's little familiar with both
        of them, the difference in the extent of existentiality each addresses is as striking as day and night. While Darwin goes on to
        say how one’s body evolved from single cell to monkey and a man, he misses out on a crucial questions like how and why! Also little
        or no addressing of anything other than physical appearance; while the former seems like a fairy tale, it advertises extraordinary
        sense of depth and understanding of the process of life in its entirety, something beyond the physical.
    </Text>

    <Text>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        The story delicately addresses how the journey of life begins under the servitude of elements,
        bound by the rules of of nature, with various analogies such as fish being bound to the water and unfolds the journey on how
        life traverses through the barrier of multitude of elements, like a amphibian tortoise, terrestrial boar and apparently surfacing
        something subtle that is unbounded by the nature, bit by bit, step by step; Instincts (Vasanas), that can be calmed by commitment
        and devotion (Narasimha - Lion), the thought and intellect that can scale the three world in other words dimensions of life, death
        and beyond (Vamana), focus, dedication, discrimination (parashurama) the qualitative aspects of life narrated in these stories nudging
        towards existential reality of awareness that starts to illuminate one’s true nature as historical figures like Rama, Krishna showcased.
        Their life is the epitome of Intelligence, unbridled love and joy beyond reasons, and gently, subtly surfacing the jewel of freedom as Buddhas,
        leading towards the true nature of life beyond elemental play described as kalki, mystic, consciousness or god a mere word can’t do justice!
    </Text>
    <br />

    <Text>
        In different times, different predominant human qualities are revered, true given the time is subjective in human consciousness its
        never the same for two individuals but if we look at the current sate of the society, its clearly in a cusp of non physical. The brain,
        Intelligence is more appreciated and playing crucial role than brawn ever did and understanding its evolution has become the paramount.
        True human evolution is complete as per Darwin’s theory but People and time has shown us again and again that there is room for human,
        brain to evolve from Having; Doing to Being.
    </Text>

    <Text>
        &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
        It took enormous, unimaginable time and unknown effort to be in state we are in this existence, yet we don’t know a single fact that’s
        complete, whole about the existence. We don’t understand a single life on this planet in its entirety, not even oneself! But in every
        point of time there was, there is a presence who have always declared that there will be the presence, comprehending the whole, showcasing
        the possibility beyond fragmentary nature to one's who are stuck. In this and many culture it was predominantly established that the humanity
        in itself is a blueprint of the existence, understanding oneself is put forth as ultimate possibility and the journey starts from having.
    </Text>
    <br />

    <Text>
        Having reached the point where one can exercise intellect, giving the taste of “will”, we are compelled to explore that “will” more and
        more in the field of human consciousness. Though it’s the outer most layer, “having” is the quality that gives one the taste of the “will”,
        the “I”, if we dare name!
    </Text>

    <Text>
        &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
        Having impels satisfaction, that I have food, I have home, I have husband/wife, I have security etc, etc but that’s as far as it goes. Once
        the satisfaction is tasted, the focus seamlessly shifts to the factor “Doing”, that enabled one to taste the satisfaction. The intricate
        relation between doing and having is illuminated.
    </Text>
    <br />

    <Text>
        As it can be seen throughout out the history scientists, athletes, musicians, artists, writers, without much concern for what they had always
        found satisfaction in what they did, as doing implies freedom, “HAVING” Freedom, “HAVING” Satisfaction! But the origin of the action is in
        itself rooted in the physicality! And science is yet to discover perpetual energy! So there is no wholeness of truth in action, only a taste
        of it, fragment of it, but not whole. When this fact grips one entirely is when one starts to wonder who’s the doer of all the
        doings? Who’s the experiencer experiencing the actions, freedom to act and occasional seeming satisfaction that accompanies the following?
    </Text>
    <br />
    <Text textAlign={"center"}>Who am I?</Text>
    <br />
    <Text>
        If I am the doer, if I have t the freedom that lets me experience satisfaction within, then what’s the need to do, can’t I just be?
        Be in the state of experience? With these deeper questioning, suddenly all the outer layer seems like a sham.
    </Text>
    <Text>
        &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
        If one can’t be in a state of experience one wants irrespective of situations, time etc, etc, is it freedom? Without freedom can one really
        do what one wants? Without being able to do what one wants can there be satisfaction? The taste of the things that led one to the question,
        themselves are shattered by the question, like the ground beneath one's feet crumbling into abyss, hurtling one into the free fall of nowhere,
        but in that land of pain there are glimpses of the being, the taste of fulfillment, foundation of all the other states but that’s just a glimpse!
        Like the taste of initial satisfaction and freedom, what’s beyond?
    </Text>
    <br />

    <Text>
        Different times named it differently god, ram, krishna, christ, consciousness etc, etc but the longing and the evolution towards it never died
        out. This is a conscious attempt for human evolution, ultimate possibility of being.
    </Text>
    <br />
    <ItalicText color={secondaryColorTwo}>
        <Text textAlign={"center"}>
            Moment of Satisfaction begot <br />
            taste of Having, <br />
            Moment of Freedom begot <br />
            the taste of Doing <br />
            Moment of Fulfillment begot <br />
            the taste of being <br />
            & all that's left is the longing, <br />
            longing for the beyond!
        </Text>
    </ItalicText>
</>

const visionTwoArticle = <>

    <Text textAlign={"center"}> Can Artificial Intelligence ever be held accountable?</Text>
    <br />
    <Text>
        This is the question of the century. Though it’s not denied, it's also not accepted that Artificial Intelligence can be held accountable.
        I mean how can humans can answer that, we still are hesitant to assume responsibility for our own mess!
    </Text>
    <Text>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        Pun aside, there may be a lot of conflicting opinions on the whole state of affairs but it has made one things clear, the difference between
        intellect and intelligence. In crude terms  the only capability of AI is applying logic of “if”, “else If” and “else” on the given data
        and the patterns are established by processing the memory of past to act in present, in reality; The present and the reality that audaciously
        if not coincidentally, refuses to fit in any pattern.
    </Text>
    <br />

    <Text>
        After millennium humanity is yet to discover the boundary of the universe and as if mockingly throwing cold water on the attempt, it seems like
        universe is ever expanding, as it expands birthing possibilities, scenarios, if, else’s; so will it be ever possible to contain ever expanding,
        seeming living entity that’s growing into patterns?
    </Text>
    <Text>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        How can the memory, memory of the patterns be held responsible when it can’t keep up with the ever growing possibilities? The era of AI has laid
        it bare on the table that bundle of memories, intellect can only be utilized but can never be held accountable! But also begs the question
        on the nature  of the utilizer of the intellect! If we can call it intelligence.
    </Text>
    <br />

    <Text>
        Human brain storage capacity is still in question, replication of human memory management is yet to be achieved;&nbsp;
        <Box as="a" href="https://www.tomshardware.com/tech-industry/full-scan-of-1-cubic-millimeter-of-brain-tissue-took-14-petabytes-of-data-equivalent-to-14000-full-length-4k-movies"
            color={secondaryColorTwo}
            _hover={{
                color: "blue",
            }}
            target="blank">
            with partial mimicking
        </Box>  its unsustainable but with deeper and deeper understanding of the nature of brain one can realize that there is memory and then there
        is living, growing, unnamable entity that’s not a pattern, not a memory, ever growing, ever expanding, like the unfathomable universe!
    </Text>
    <br />
    <Text>
        If the nature of of universe it truly “uni” - versa; one and not two or three, timeless in nature without the beginning and the end!
        The intelligence that ever expands, the intelligence utilizing the intellect; doesn’t that mean human intelligence is universal in nature?!
        But what is the nature of this seemingly timeless intelligence unbounded by the contradiction of time, now and then, Here and there,
        Life and death?
    </Text>
    <Text>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        As a possibility put forth by the time itself, if at all there’s such a timeless intelligence beyond memory and thought that can only
        be rediscovered!
    </Text>
    <br />
    <Text textAlign={"center"} >Which already exists! </Text>
    <br />
    <Text>
        Isn’t this the greatest, most appropriate time when AI is understood, when intellect is at its peak and  sharpest,
        to embark on this journey?!
    </Text>
</>

const visionThreeArticle = <>
    <Text>
        Even when all the  conditions are set and human intellect is functioning on its peak in know human history, we are struggling, isn’t it
        important to look at why we are struggling? Struggling ethically, mentally, in turn causing physical struggles that can be taken care of
        with right care and attention!
    </Text>
    <Text>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        Societal status, jobs, insurance, security, has become paramount importance! Cyber bulling, mental illness, abnormal societal standards,
        suicide has become norm! Isn’t this the ripe time to take a fresh look at where we are heading towards? Why we are struggling even when
        we think we are collectively functioning at our highest possibility?
    </Text>
    <br />
    <Text>
        At the least some of us, before the explosion of digital technology, in childhood didn’t we live our days without any burdens dragging us down?
        Eating what ever our mother’s cooked, playing whenever situation allowed and resting without the thought of tomorrow; so what changed?
    </Text>
    <Text>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        Sure responsibility grew but wasn’t that why we were educated, to deal with them in a finer possible manner and be joyful? But even after
        eighteen years of formal education why are we struggling with survival aspects like food, clothes, sheltering, insurances, job money,
        everything that was created by humans; why are we held back by the very things created by humans to propel us forward?  Was it really worth
        spending eighteen years of one’s life over things that fills one with more fear? More restrictions? Curbing freedom everyone born with?
    </Text>
    <br />
    <Text color={secondaryColorTwo} textAlign={"center"}>Is our education really what it should be?</Text>
    <br />
    <Text>
        We never questioned our identities and blindly accepted someone else’s idea of who we are, name given by parents, status given by society and
        in a way by time, which in itself is ever changing!
    </Text>
    <Text>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        No wonder the anxiety, stress and desperation is pestering in preserving, protecting and guarding the boundaries that forms one’s identities,
        while the time rips it apart bringing the dread of an end, the death, but when we actually look at it do we even know who was born,
        who is living, who will be that's dying and who is this even uttering the syllable “I” ?
    </Text>
    <br />
    <Text>
        Is it intelligent to be in the grasp of fear without even knowing the nature of the fear or oneself? Is one’s time really worth the education
        that subjects one to various fears, and sufferings? Is it still relevant to the time we are in? Or should we be taking a fresh look at what we
        call as “I”, what we think is the “education” and “life”?
    </Text>
    <Text>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        Isn’t it worth creating an ambience and situations where we can collectively explore the true nature of life beyond the societal norms?
        beyond the relics of the past?!
    </Text>
    <br />
    <Text>
        Does one’s education truly ends after earning a paper? Is learning only meant for putting a bread on the table and be in the dread of the
        unknown forever?
        Aren’t these questions worth acted at? Looked at?
    </Text>
</>

const missionOneArtice = <>
    <Text>
        The education in western countries has evolved and skills such as carpentry, cooking, archery, swimming etc are part of the curriculum
        but why are we stuck in our mediocre idea of modern western education?
    </Text>
    <Text>
        &nbsp; &nbsp; &nbsp; &nbsp;
        The western education is steadily going back to the template of traditional Indian education system baring the ignorance of why it was so in our culture;
        they think it's their invention.
    </Text>
    <br />

    <Text>
        Anyway, these essential skills are so valued and ingrained in the current western societal structure that they are treated equal to any other profession.
        In fact most tend to learn and do it on their own rather than spending a hefty price in it, but why are we stuck in our prejudice that being employed is
        the only respectable profession?
    </Text>
    <Text>
        &nbsp; &nbsp; &nbsp; &nbsp;
        Looking back at our history and heritage if we observe carefully it is pleanty evident that what flourished and made our culture, country great was the
        Entrepreneurial attitude, micro, distributed markets and economy flourishing in the melting pot of diversity,
        in contrast to the capitalism we are heading towards.
    </Text>
    <br />

    <Text>
        The generation of population in urban has almost no insight on any of the things other than academics, job hunt, living pay check to pay check, indulging
        in weekend parties and receding capability to handle stress, challenges and disruption.
    </Text>
    <Text>
        &nbsp; &nbsp; &nbsp; &nbsp;
        To speak of my own experience I never found the academic rat race exciting, in fact it was unnecessary pressure, but got through it till masters with
        attitude that I don't have any other choice. Even now after having a lot of exposure to various things and mustering up courage to step outside
        of the social norm, all i have faced is failures.
    </Text>
    <br />
    <Text>

        Failures in various dimensions such as emotional, societal etc., True one can't succeed in a single try or with breeze but then why has it been made
        such that failure is the end of life? why does our society measures success or failure in comparison to others? or sets a standard on what success or failure
        is?
    </Text>
    <Text>
        &nbsp; &nbsp; &nbsp; &nbsp;
        Why is our society not inclusive of failure in its structure but rather exclusive, absuvie of failures, making individuals dare not even to try?
        If the so called society, collective human consciousness smothers creativity and love on each and every step how can we and our future generation have
        well being while dancing on the palms of few corporate gaints pre occupied, obsessed with their own limitations, limited idea of well being?
    </Text>
    <br />

    <Text>
        Youth, child suicide rate due to academic pressure, unemployment, relationship failure is proliferating! why are they feeling abandoned?
        why is it ending life or mediocre life would even seem like an option?
    </Text>
    <Text>
        &nbsp; &nbsp; &nbsp; &nbsp;
        isn't society meant to be inclusive? having place for every kind of unique life rather than serving single interest?
    </Text>
    <br />

    <Text color={secondaryColorTwo}>
        This is an attempt to envision and take a step towards inclusive, learning environment for individuals, an attempt to explore life individually,
        totally with everyone an attempt towards true melting pot of diversity.
    </Text>
</>

const missionTwoArticle = (<>
    <Text>
        In japan, KFC a fast food chain brand has been made synonymous with christmas itself, "Kentucky is Christmas" they say, and for a matter of fact
        every one knows its not! well at least the generations before KFC's establishment and its raise in popularity are aware of the fact, but the younger
        generation does actually think Kentucky is Christmas! not Jesus Christ but Kentucky is!
    </Text>
    <Text textAlign={"center"} color={secondaryColorTwo}>
        How did it come to this?!
    </Text>
    <Text textAlign={"center"}>
        Isn't it the same as how we came to believe money, car, house etc etc is happiness?
    </Text>
    <br />

    <Text>
        Its a simple observation, that thought, emotion, feelings and of course happiness originates from within but most of us are convinced that it is
        something to be chased after rather than looked at, or be with what already is. Sure its not a matter of one can or can't, want or don’t, one's capable or not but the conglomeration of situations and the amalgamation of boundaries;
        of course physical boundaries of various kinds but mostly psychological.
    </Text>
    <br />

    <Text>
        Only a man who doesn't know hunger is capable of preaching to a person dying of hunger, and treating food like a commodity.
    </Text>


    <Box display={"flex"} flexDir={{ base: "column", md: "row" }} justifyContent={"space-evenly"}>
        <Box display={"flex"} flexDirection={"column"} height={{ base: "18rem" }} width={{ base: "full", md: "15rem", lg: "20rem" }}
            cursor={"pointer"} as="a"
            href={"https://www.unep.org/news-and-stories/press-release/world-squanders-over-1-billion-meals-day-un-report"}
            target="_blank">
            <Box height={{ base: "15rem" }} width={"full"}
                backgroundImage={foodWastage}
                backgroundSize={"30rem"}
                bgPos={"50%"}
                backgroundRepeat={"no-repeat"}
                textAlign={"center"}
            />
            <Box textAlign={"center"}>How much Food we waste?</Box>
        </Box>

        <Box display={"flex"} flexDirection={"column"} height={{ base: "18rem" }} width={{ base: "full", md: "15rem", lg: "20rem" }}
            cursor={"pointer"} as="a"
            href={"https://www.who.int/news-room/fact-sheets/detail/suicide"}
            target="_blank">
            <Box height={{ base: "15rem" }} width={"full"}
                backgroundImage={suicideprevention}
                backgroundSize={"18rem"}
                bgPos={"50%"}
                backgroundRepeat={"no-repeat"}
                textAlign={"center"}
            />
            <Box textAlign={"center"}>How many are killing oneself?</Box>
        </Box>
    </Box>

    <Text>
        Only a man deprived of clarity can think there can be fulfillment in division and walling up inside one's psychological structure and identities.
        Only a man who doesn't know the joy of life can be trapped in the pressure of education, pleasure, and pain of capitalism.
    </Text>
    <br />
    <Text textAlign={"center"} color={secondaryColorTwo}>
        Only a man who is not aware of one who draws this breath can be afraid of death
    </Text>
    <br />
    <Text>
        The fulfillment of of life lies in addressing these situation not as a part but as a whole in whatever the capacity possible subjective
        to time, doesn’t it?
    </Text>
    <br />
    <Box as='i' textAlign={"center"} color={secondaryColorTwo}>
        <Text>
            Sun burns for naught,<br />
            yet is a reason for life; <br />
            Wind blows with no obligation,<br />
            yet life breaths into ecstasy; <br />
            Water flows with no agenda,<br />
            yet merges & becomes everything; <br />
            Worm needs no harvest,<br />
            yet ploughs the soil; <br />
            Flower possess no fragrance,<br />
            yet blooms; <br />
            <br />
            When everything are in action, inclusive of existence; <br />
            what else action of self can be?
        </Text>
    </Box>
</>)

const missionThreeArticle = (<>
    <Text textAlign={"center"} color={secondaryColorTwo}>
        The saturation of having, the limitation of doing, without them how can one arrive to seek the being?
    </Text>
    <br />

    <Text>
        There's an order to everything unless its true nature is experienced in whole, which is always cyclical. Once the cyclical nature of a process
        is in experience, the order which seemed like a logical explanation and understanding feels like a farce!
    </Text>
    <br />

    <Text>
        It's like knowing the truth that there can't be water without rain unless one enquires the nature of the process of the rain.
        Once the whole process of water cycle is in perception the truth that rain is source of water falls apart but would it make rain being a source of
        water a lie? Does it?
    </Text>
    <Text>
        &nbsp; &nbsp; &nbsp; &nbsp;
        Though it doesn't, it also makes one thing apparent, that the rain being the medium is not the origin! Our understanding of the source was mere
        expression of the origin that lays uninquired, unseen, waiting to be uncovered and experienced.
    </Text>
    <br />

    <Text textAlign={"center"} color={secondaryColorTwo}>
        The happiness of having, a mere expression of the source isn't a lie, but is it the origin?
    </Text>
    <br />

    <Text wordBreak={"break-word"} overflowWrap={"break-word"} >
        Having tasted the expression isn't the science and advancement means knowing its origin within oneself?
        <br /> <br />
        Like tracing the flow of the water, which illuminates the nature of the cycle when its destination is discovered;
        Our mission is, doing what's necessary to set the stage and create the ambiance for more and more people to see beyond
        <Box color={secondaryColorOne}
            fontWeight={'bold'}
            as={'a'} href="/blog?id=vision_one"> "Having"</Box>
        and join together in a journey to explore life in its totality to deepen the perception on the the cycle of life and death!
    </Text>
    <br />

    <Text>
        Though our "ithihasa", the rich history, whihc seem like mixture of many things, in all its essence is just an attempt to convey a single fact;
        To put it in words relevant to today's world by borrowing the words of swami vivekananda,
    </Text>
    <br />
    <Text textAlign={"center"} color={secondaryColorTwo}>
        "The advancement of human civilization is not in hoarding material wealth  <br />
        cooping up in the concrete cities, closed of to the nature <br />
        but in understanding the human senses, perception <br />
        and reaching the epitome of understanding <br />
        or rather<br />
        in experiencing this phenomenon we call life." <br />
    </Text>
    <br />

    <Text textAlign={"center"}>
        The drawer of this breath, the ultimate expression of existence, the origin;  isn't that a joy?
    </Text>
    {/* (links to old age home & support, retirement activities - teaching, gardening, cooking? supporting others? etc etc) */}
</>)


const visionBlogHref = {
    one: "/blog?id=vision_one",
    two: "/blog?id=vision_two",
    three: "/blog?id=vision_three"
}
const missionBlogHref = {
    one: "/blog?id=mission_one",
    two: "/blog?id=mission_two",
    three: "/blog?id=mission_three"
}

const libraryHref = {
    one: "/blog?id=library_one",
    two: "/blog?id=library_two",
    three: "/blog?id=library_three"
}

const visionBlogTitle = {
    one: "Having Doing Being!",
    two: "Intelligence Amid AI!",
    three: "Survival To Creative!"
}
const missionBlogTitle = {
    one: "Nurturing Life Beyond Ideas!",
    two: "Action OF Self!",
    three: "Spreading JoY"
}

const libraryBlogTitle = {
    one: "Why Read?!"
}


const visionOne = {
    "id": "one",
    "groupId": "vision",
    "title": visionBlogTitle.one,
    "image": ultimatefreedom,
    "imageAlt": "ultimate freedom    Image source: https://www.instagram.com/isha.foundation/",
    "body": {
        "title": "From Having To Doing To Being",
        "content": visionOneArticle,
        "shortContent": "In different times, different predominant human qualities were revered, given the time is subjective in human consciousness..."
    },
    "footer": "Being?",
    "isArticle": false,
    "isInitiallyOpen": false,
    "href": visionBlogHref.one,
    "next": {
        "href": visionBlogHref.two,
        "title": "Intelligence Amid AI!"
    },
}

const visionTwo = {
    "id": "two",
    "groupId": "vision",
    "title": visionBlogTitle.two,
    "image": intelligence,
    "imageAlt": "unlocking life    Image source: https://www.instagram.com/isha.foundation/",
    "body": {
        "title": "In Era of AI Seeking Intelligence of Being",
        "content": visionTwoArticle,
        "shortContent": "The era of AI has laid it bare on the table that bundle of memories, intellect can only be utilized but can never be held accountable..."
    },
    "footer": "What?",
    "isArticle": false,
    "isInitiallyOpen": false,
    "href": visionBlogHref.two,
    "previous": {
        "href": visionBlogHref.one,
        "title": visionOne.title
    },
    "next": {
        "href": visionBlogHref.three,
        "title": visionBlogTitle.three
    }
}

const visionThree = {
    "id": "three",
    "groupId": "vision",
    "title": "Survival To Creative!",
    "image": coulditbeeasy,
    "imageAlt": "could it be easy to know? Image source: https://www.instagram.com/isha.foundation/",
    "body": {
        "title": "Survival Orientation To Creative Conscious Living",
        "content": visionThreeArticle,
        "shortContent": "Is it intelligent to be in the grasp of fear without even knowing the nature of the fear or oneself? Is one’s time really worth the education that subjects..."
    },
    "footer": "How?",
    "isArticle": false,
    "isInitiallyOpen": false,
    "href": visionBlogHref.three,
    "previous": {
        "href": visionBlogHref.two,
        "title": visionBlogTitle.two
    },
    "next": {
        "href": missionBlogHref.one,
        "title": missionBlogTitle.one
    }
}


const missionOne = {
    "id": "one",
    "groupId": "mission",
    "title": missionBlogTitle.one,
    "image": reapwhatyousow,
    "imageAlt": "reap what you sow Image source: https://www.instagram.com/isha.foundation/",
    "body": {
        "title": "Community to nurture Life In Existentiality",
        "content": missionOneArtice,
        "shortContent": "Looking back at our history & Heritage, the entrepreneurial attitude, micro, distributed markets & economy flourished  in the melting pot of diversity, in contrast to the capitalism...."
    },
    "footer": "How?",
    "isArticle": false,
    "isInitiallyOpen": false,
    "href": missionBlogHref.one,
    "previous": {
        "href": visionBlogHref.three,
        "title": visionBlogTitle.three
    },
    "next": {
        "href": missionBlogHref.two,
        "title": missionBlogTitle.two
    }
}

const missionTwo = {
    "id": "two",
    "groupId": "mission",
    "title": missionBlogTitle.two,
    "image": savesoil,
    "imageAlt": "save soil Image source: https://www.instagram.com/isha.foundation/",
    "body": {
        "title": "Doing What Really Matters To Oneself & Life Around",
        "content": missionTwoArticle,
        "shortContent": "Only a Person who don't know hunger is capable of treating food as a commodity, only a man without clarity can imagine fulfillment in division..."
    },
    "footer": "What?",
    "isArticle": false,
    "isInitiallyOpen": false,
    "href": missionBlogHref.two,
    "previous": {
        "href": missionBlogHref.one,
        "title": missionBlogTitle.one
    },
    "next": {
        "href": missionBlogHref.three,
        "title": missionBlogTitle.three
    }
}

const missionThree = {
    "id": "three",
    "groupId": "mission",
    "title": missionBlogTitle.three,
    "image": spreadingjoy,
    "imageAlt": "spreading joy Image source: https://www.instagram.com/isha.foundation/",
    "body": {
        "title": "Discovering the JoY of Being",
        "content": missionThreeArticle,
        "shortContent": "Doing what's needed to set the stage and create the ambiance to go beyond having; towards exploration of life in its totality to ..."
    },
    "footer": "Being?",
    "isArticle": false,
    "isInitiallyOpen": false,
    "href": missionBlogHref.three,
    "previous": {
        "href": missionBlogHref.two,
        "title": missionBlogTitle.two
    },
    "next": {
        "href": libraryHref.one,
        "title": libraryBlogTitle.one
    }
}


export const visionCards = [visionOne, visionTwo, visionThree]
export const missionCards = [missionOne, missionTwo, missionThree]

export const mainPageIntroduction = (<Box
    width={{ base: "100%", lg: "40vw" }}
    maxW={{ base: "100%", lg: "50rem" }}

>



    <Text fontSize={headingPrimaryFont} color={primaryColor}>Introduction</Text>
    <Box textAlign={"justify"} color={secondaryColorTwo} >
        <Text>
            Two generations ago a picture of a family would be that of a honeycomb;
            today it’s a struggle to trust anyone! and consequences are innumerable
            but one that needs imminent attention is mental health crisis individuals
            are struggling through in various levels.
        </Text>

        <Text>
            &nbsp; &nbsp; &nbsp; &nbsp; From joint family to suicide due to loneliness, stress
            and fear of failures; From caring community based society to capitalist, competitive,
            jealousy; Are we progressing or regressing?
        </Text>

        <Text>
            <br />
            Our Vision and Missions are outlined in a way to create a conducive environment
            for individuals, and mainly our future generations to explore their potential
            without being held back by fear and propagate tools in supporting them.
            In a nutshell this is an attempt to re evaluate modern problems in the light
            of timeless wisdom of Inclusiveness and perception of life beyond limitation
            of ego and, understand self in the moment without the frame of past, present and future.
        </Text>
    </Box>
</Box>)

export const schoolPageIntroduction = (<Box
    width={{ base: "100%", lg: "35vw" }}
    maxW={{ base: "100%", lg: "40rem" }}
    pr={"3rem"}
    paddingTop={{ base: headingSecondaryFont, lg: "0" }}>
    <Text fontSize={headingPrimaryFont} textAlign={"center"} color={primaryColor}>Home School</Text>
    <Box textAlign={"justify"} color={secondaryColorTwo}>
        <Box textAlign={"center"} >
            <Box as='i'>
                Our Syllabus?
                <br />
                Quite simple Really
                <br />
                Just a line. <br />
                "Who am I?"
            </Box>
        </Box>

        <Text>
            <br />
            Our relation lasts only till we graduate, and beyond that? <br />
            Now, Who knows?
            <br /> <br />
            The world is becoming dangerously polarized, fragmentary in nature, with unsustainable practices.
            <a href="https://consciousplanet.org/en/save-soil" target="_blank" >
                <Box textAlign="center" color={primaryColor}> Our soil health is prime example of this. </Box>
            </a> <br />
            <Box textAlign={"center"}> As the saying goes "Reach & touch the sky while being firmly rooted." </Box>
            As showcased in our history, what is education if not understanding, carefully witnessing the
            inclusive nature of the existence and our being?
            <Author />
        </Text>

        <Text>
            <br />

        </Text>
    </Box>
</Box>)

const libraryOneArticle = (<>
    <Text>
        From time immemorial, extraordinary importance has been attached for the act of reading. Irrespective of race, caste, creed, religion
        ideology and language etc education starts with letters in order to construct words and sentences in order to establish a pattern which
        is considered as meaning of the sounds, but what for?
    </Text>
    <Text textAlign={"center"}>
        To express, project the inward movements out to the world? May be so!
    </Text>
    <br />
    <Text>
        The importance of communication in the world is undeniable fact beyond a certain point. A child is taken care off till the age but later on
        he or she has to communicate in order to survive, thus starts the drilling of patterns into young tender minds that ardently refuses to fit in!
        but as the vibrancy is chipped away like a natural phenomenon, mind starts to fit in a mould.
    </Text>
    <Text>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        yes reading is a great tool in order to learn, communicate and express but is it limited to the pattern?
    </Text>
    <br />
    <Text>
        A dull mind settles to the pattern, but the vigours mind can't help itself but wonder what's  beyond! True, a pattern equips the mind with
        necessary ingredients to express itself for various survival actions but it doesn't answer the question of how these patterns were woven?
        because the pattern, the language itself is not the meaning, it's just a tool for expressing inner movements.
    </Text>
    <Text>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        As the inner movements grow in its complications, the meaning, the language itself becomes fuzzy! isn't it? There's little or no misunderstanding
        when the expression of humana originates from survival aspects of life like hunger, physical pain and needs or need for respect to a certain extent
        but man oh man, beyond these, there are whole bunch of pandora boxes!
    </Text>
    <br />
    <Text>
        Emotions such as pride, happiness, sadness, love and the mother of all the confusions the ego and meaning of life. who can define these complicated
        inner movements in a set of pattern? Sure they have been philosophised  but that's about it.
    </Text>
    <Text>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        Most people are touched and jolted to something indescribable mostly  by artistic works than philosophies, is it coincidence? perhaps not don't you
        think? The stories that seems to be incomplete, left to one's imagination strikes the chords, living inside us making a impact than a sentence ascribing to
        be meaning in dictionary! True it is important to learn to fit in to an extent but why limited by these patterns?
    </Text>
    <br />
    <Text>
        Once we pass the horizon of survival aspects, the process of readin g acquires a whole different quality! An awareness that meanings were given to the
        words by inner movements and the understanding of the inner realm is not in expressing but in experience. Philosophy is a great intermediary tool but understanding of the complicated human emotions doesn't happen from the patterns of philosophical expressions
        but by the sophisticated practice of stillness!
    </Text>
    <Text>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        In the process of life after churning for a various things begetting, entangling inner movements, now
        its time to wait for the disturbances to settle down and the surface to be unmuddled.
    </Text>
    <br />
    <Box textAlign={"center"} as="i" color={secondaryColorTwo}>
        <Text>Reading is that wonderful omni-purpose tool of utilization;</Text>
        <Text>churn the inner dimension,</Text>
        <Text>indicator to wait</Text>
        <Text>a mirror to the self.</Text>
    </Box>
</>)


export const libraryMainBlog = {
    "id": "one",
    "groupId": "library",
    "title": libraryBlogTitle.one,
    "image": read,
    "imageAlt": "PlaceHolder",
    "body": {
        "title": "LIBRARY - Life Is Beautiful in Reality And Realizing Yourself",
        "content": libraryOneArticle,
        "shortContent": `From time immemorial, extraordinary importance has been attached for the act of reading. 
        Irrespective of race, caste, creed, religion ideology and language etc education starts with letters in order to construct words and sentences in order
         to establish a pattern which is considered as meaning of the sounds, but what for? (Meaning is a pattern)
         \n \n \n
         To express or project the inward movements out to the world? May be so! 
         `
    },
    "footer": "Read",
    "href": libraryHref.one,
    "isArticle": false,
    "isInitiallyOpen": false,
    "previous": {
        "href": missionBlogHref.three,
        "title": missionBlogTitle.three
    }
}


export const libraryPageIntroduction = (<>
    <Box
        width={{ base: "100%", lg: "35vw" }}
        maxW={{ base: "100%", lg: "40rem" }}
        pr={"3rem"}
        paddingTop={{ base: headingSecondaryFont, lg: "0" }}>

        <Text fontSize={headingPrimaryFont} textAlign={"center"} color={primaryColor}>Life Is Beautiful in Reality And Realizing Yourself</Text>
        <Box textAlign={"justify"} color={secondaryColorTwo}>
            <Box textAlign={"right"} >
                <ItalicText> ~~~ LIBRARY</ItalicText>
            </Box>
            <br />
            <Text color={primaryColor} fontSize={secondaryFontSize}>Why Read?</Text>
            <Text>
                The Books are Kalpataru of the material world, mirror of the inner dimension, the bridge and the platform for the life to dance and find expression.
            </Text>
            <Author />
        </Box>
    </Box>
</>)





export const libraryCards = [libraryMainBlog]


export const iEExperiences = [{
    "img": me,
    "imgAlt": "Sharath",
    "title": "Sharath",
    "subtitle": "Software Engineer",
    "shortContent": "As i anguish the strangling of the subjective boundries of the limited perception, Inner Enggineering came like a natural phenomenon, akin to a season for the flowers to  bloom.",
    "content": `Though it is obvious that the life stems from the objective, progressing through the subjective and ultimately culminating where it all began,
                the mind struggled to come to terms with it being torn apart by the subjective boundries; Inner Enggineering is a way of liting a lamp to see that
                its not life and death but birth, living and death are nothing but a phases of life;
                its not consciousness and perception but clarity, stability and perception are the phases of consciousness.`,
    "hoverTitle": "Read More"
},]

export const libraryWeHaveBooks = (<Box
    width={{ base: "100%", lg: "40vw" }}
    maxW={{ base: "100%", lg: "50rem" }}
    h={{ base: "16rem", lg: "20rem" }}
>
    <Box className="heading" mt="1rem">
        <Heading color={primaryColor} textAlign={"center"} pb={"1rem"}>We Have Books!</Heading>
    </Box>

    <Box textAlign={"justify"} color={secondaryColorTwo} h={{ base: "10rem" }} alignContent="center" overflow={"scroll"} >
        <Text>
            yeah, Of All sorts. Children stroy books, Novels, a few Professional Academic one's, Self Help and of course Spiritual books.
        </Text>

        <Text>
            &nbsp; &nbsp; &nbsp; &nbsp; we have a small, quite reading space for you to pick any of the book and immerse yourself into it.
            It's also allowed to take books with you on individual basis after checking with the admin
            <br />
            <br />
            We organise reading sessions as well, please contact admin for more details.
        </Text>
    </Box>
</Box>)

export const libraryWeAcceptBooks = (<Box
    width={{ base: "100%", lg: "40vw" }}
    maxW={{ base: "100%", lg: "50rem" }}
    h={{ base: "16rem", lg: "20rem" }}
>
    <Box className="heading" mt="1rem">
        <Heading color={primaryColor} textAlign={"center"} pb={"1rem"}>Still We Collect Books!</Heading>
    </Box>

    <Box textAlign={"justify"} color={secondaryColorTwo} h={{ base: "10rem" }} alignContent="center" overflow={"scroll"} >
        <Text>
            As the saying goes "Half knowledge is dangerous" and the cure for it is not ignorance but more knowledge!
        </Text>

        <Text>
            &nbsp; &nbsp; &nbsp; &nbsp; We take in any books that you think is worthy of attention and spreads good will
            in society; of course it has to be in a readable condition.
        </Text>
        <br />
        <Text >
            Please drop the books to us personally or mail it to our
            <Box as='a' href='/library#contactUs' color={primaryColor}> location.</Box>
        </Text>
    </Box>
</Box>)