import ResponsiveNavBarContainer from "../components/NavBar/ResponsiveNavBarContainer";
import IntroductionWithImage from "../components/IntroductionWithImage";
import MainImageCarousel from "../components/MainImageCarousel";
import StickyNavAndFooter from "./layout/StickyNavAndFooter";
import { libraryMainBlog, libraryPageIntroduction, libraryWeAcceptBooks, libraryWeHaveBooks } from "../data/data";
import { Box, Button, Card, CardBody, CardHeader, Heading, Text, useBreakpointValue, useDisclosure } from "@chakra-ui/react";
import read from '../resources/images/read.jpeg'
import { headingPrimaryFont, primaryColor, secondaryColorTwo } from "../config";
import wehavebooks from '../resources/images/wehavebooks.jpg'
import mailUsBooks from '../resources/images/mailusbooks.webp'
import Post from "../components/ModalPost";
import { CarouselCardDetails_t } from "../components/Carousels/CarouselCard";

export default function Library() {
    const { isOpen, onToggle } = useDisclosure()
    const libraryMainBlogForLibPage: CarouselCardDetails_t = libraryMainBlog
    delete libraryMainBlogForLibPage.previous;
    delete libraryMainBlogForLibPage.next;
    function onClose(parentId: string) {
        onToggle()
    }

    return (
        <StickyNavAndFooter>
            <ResponsiveNavBarContainer />
            <MainImageCarousel />
            <IntroductionWithImage img={read}>
                {libraryPageIntroduction}
                <br />
                <Box textAlign={"right"}>
                    <Button bg={primaryColor} color={"white"} onClick={onToggle}
                        _hover={{
                            backgroundColor: secondaryColorTwo,
                        }}>Read</Button>
                </Box>
            </IntroductionWithImage>
            <br />
            <Card pl={{ base: 0, md: "4rem" }} id="lets-read">
                <CardHeader p={0} pt={"2rem"}>
                    <Text fontSize={headingPrimaryFont} color={primaryColor}
                        textAlign={"center"}
                    >Let's Begin!</Text>
                </CardHeader>
                <CardBody p={0}>
                    <Box overflow={"scroll"}>
                        <Box id={"main-introduction"}>
                            <IntroductionWithImage img={wehavebooks}
                                className="library"
                                invert={useBreakpointValue({ base: false, lg: true })!}>
                                {libraryWeHaveBooks}
                            </IntroductionWithImage>
                        </Box>
                        <Box>
                            <IntroductionWithImage img={mailUsBooks}
                                className="library">
                                {libraryWeAcceptBooks}
                            </IntroductionWithImage>
                        </Box>
                    </Box>
                </CardBody>
            </Card>
            {isOpen && <Post card={libraryMainBlogForLibPage}
                isOpen={isOpen}
                onClose={onClose} />}
        </StickyNavAndFooter>
    )
}