import { Box, Text } from "@chakra-ui/react";
import Carousel from "./Carousel";
import { useBreakpointValue } from '@chakra-ui/react'
import CarouselCard, { CarouselCardDetails_t } from "./CarouselCard";
import { Settings } from "react-slick";
import { bodyBGColor, headingPrimaryFont, headingSecondaryFont, maxWidth, primaryColor, primaryFontSize, secondaryColorTwo, secondaryFontSize } from "../../config";
import { CarouselcontainerStyles_t } from "./carousalTypes";
import DividerAbsoluteCenter from "../DividerAbsoluteCenter";

export interface CardCarousalProps_t {
    cards: CarouselCardDetails_t[];
    imageHeight: string;
    containerTitle?: string;
    subTitle?: string;
    carouselSettings?: Settings;
    footerButtonHandleClick?: (id: string) => void;
}

export default function CardCarousal({ cards, imageHeight, containerTitle, subTitle, footerButtonHandleClick,
    carouselSettings = {
        fade: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                }
            },

            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    autoplay: true,
                    autoplaySpeed: 5000
                }
            },
        ]
    } }: CardCarousalProps_t) {

    const carouselItems = cards.map(card => (
        footerButtonHandleClick ? <CarouselCard key={card.id} card={card} imageHeight={imageHeight!}
            footerButtonHandleClick={footerButtonHandleClick}
        /> :
            <CarouselCard key={card.id} card={card} imageHeight={imageHeight!} />
    ))

    const arrows = useBreakpointValue({ ssr: false, base: true, lg: false })
    if (!carouselSettings.arrows) {
        carouselSettings.arrows = arrows
    }

    const carouselContainerStyle: CarouselcontainerStyles_t = {
        position: useBreakpointValue({ base: "relative", lg: "static" })!,
        height: useBreakpointValue({ base: "30rem" })!,

        width: 'full',
        overflow: 'hidden',
        zIndex: 0
    }

    return (
        <Box
            ml={headingSecondaryFont}
            mr={headingSecondaryFont}
            mb={{ base: headingSecondaryFont, lg: primaryFontSize }}
            maxW={{ base: "100vW", "2xl": maxWidth }}
        >
            {containerTitle &&
                (<DividerAbsoluteCenter bg={bodyBGColor} dividerBorderColor={primaryColor}>
                    <Text fontSize={headingPrimaryFont} color={primaryColor}>{containerTitle}</Text>
                </DividerAbsoluteCenter>)
            }
            {
                subTitle && <Box textAlign={"center"}>
                    <Text fontSize={secondaryFontSize} color={secondaryColorTwo}>{subTitle}</Text>
                </Box>
            }
            <br />
            <Carousel cards={carouselItems} carouselSettings={carouselSettings}
                sliderArrowLeftTop={useBreakpointValue({ base: "30%" })}
                sliderArrowRightTop={useBreakpointValue({ base: "30%" })}
                containerStyles={carouselContainerStyle}
            />
        </Box>)
}