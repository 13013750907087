import { AbsoluteCenter, AspectRatio, Box, Button, Center, Divider, Image, Text, Tooltip, useBoolean, useBreakpointValue } from "@chakra-ui/react";
import ie from '../resources/images/ie.jpeg'
import { bodyBGColor, headingPrimaryFont, headingSecondaryFont, primaryColor, primaryFontSize, secondaryColorOne, secondaryColorTwo, secondaryFontSize, smallFontSize, white } from "../config";
import IEVideo from '../resources/videos/IEVideo.mp4'
import DividerAbsoluteCenter from "./DividerAbsoluteCenter";

interface IEnggProps_t {
    id?: string
}

export default function IEngg({ id }: IEnggProps_t) {
    const [videoFlag, setVideoFlag] = useBoolean(false)
    const aspectRatio = useBreakpointValue({ base: (6.5 / 4) })
    const videoWidth = useBreakpointValue({ base: "20rem", md: "35rem", lg: "25rem", "xl": "30rem", "2xl": "38rem" })
    return (
        <Box id={id}>
            <DividerAbsoluteCenter bg={bodyBGColor} dividerBorderColor={primaryColor}>
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    flexDir={"column"}
                >
                    <Text fontSize={{ base: primaryFontSize, md: headingSecondaryFont }}
                        color={"#2c43f5"}
                    >
                        Inner Engineering!
                    </Text>
                    <Text fontSize={{ base: smallFontSize, md: primaryFontSize }} color={"#2c43f5"}
                        alignSelf={"center"}>
                        the art of being...
                    </Text>
                </Box>
            </DividerAbsoluteCenter>
            <Box
                display={"flex"}
                justifyContent={{ base: "space-around", lg: "space-between" }}
                flexDirection={{ base: "column", "lg": "row" }}
                alignItems={"center"}
                m={{ base: headingSecondaryFont, lg: "4rem" }}

            >
                <Box minW={{ base: "90vw", "lg": "40vw", xl: "40rem" }} mr={headingPrimaryFont} ml={headingPrimaryFont}
                    cursor={"pointer"}
                    title={videoFlag ? "Stop" : "Play"}>
                    {
                        videoFlag ?
                            <Box
                                onClick={setVideoFlag.toggle}
                                bg={primaryColor}
                                height={{ base: "auto", lg: "28rem" }}
                                minH={{ base: "25rem", lg: "auto" }}
                                minW={{ base: "100%", lg: "10rem" }}
                                alignContent={"center"}>
                                {/* <Image src={ie} objectFit="contain" height={"100%"} /> */}
                                <AspectRatio w={videoWidth} ratio={aspectRatio} m={"auto"}>
                                    <iframe
                                        title='naruto'
                                        src={IEVideo}
                                        allowFullScreen
                                    />
                                </AspectRatio>
                            </Box> : <Box
                                onClick={setVideoFlag.toggle}
                                bg={primaryColor}
                                height={{ base: "auto", lg: "28rem" }}
                                minH={{ base: "25rem", lg: "auto" }}
                                minW={{ base: "100%", lg: "10rem" }}
                                alignItems={"center"}
                                display={"flex"}
                                m={"auto"}
                                justifyContent={"center"}>
                                <Image src={ie} objectFit="contain" height={"100%"} width={"90%"} />
                            </Box>}

                    <br />
                </Box>
                <Box display={"flex"} flexDirection={"column"}>
                    <Box>
                        <Text fontSize={headingSecondaryFont} textAlign={"center"}
                            pb={"2rem"}
                            color={"#2c43f5"}
                        >
                            TAKE CHARGE OF YOUR LIFE!
                        </Text>
                        <Text textAlign={"justify"} pb={"2rem"}>
                            So we have done lot of engineering in the world outside and because of this we have a huge level of comfort and convenience
                            in the world today. The kind of comfort and convenience that no other generation of people could have ever imagined, that level
                            of comfort and convenience we have. Though we have this, though we are the most comfortable generation ever on the planet,
                            we cannot claim that we are the most peaceful generation, we cannot claim we are the most joyful or loving generation on the
                            planet for sure. So in many ways, this generation is becoming the most neurotic generation or most complaining generation because
                            we have taken care of the outside but we have not taken care of the inside. The world is engineered the way we want it, but are we
                            engineered the way we want ourselves to be?
                        </Text>
                    </Box>
                    <Box display={"flex"} justifyContent={"center"}>


                        <Button bg={primaryColor} color={"white"} minW={"4rem"}
                            as='a'
                            href="https://isha.sadhguru.org/in/en/inner-engineering-pc"
                            target="_blank"
                            m={2}
                            _hover={{
                                backgroundColor: "#2c43f5",
                            }}> Register</Button>
                        <Button bg={primaryColor} color={"white"} minW={"4rem"}
                            m={2}
                            as='a'
                            href="/our-experiences"
                            _hover={{
                                backgroundColor: secondaryColorTwo,
                            }}> Our Experience</Button>
                    </Box>
                </Box>
            </Box>
        </Box>)
}