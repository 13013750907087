import { useBreakpointValue } from '@chakra-ui/react'
import { CarouselCardDetails_t } from './Carousels/CarouselCard';
import CardCarousal from './Carousels/CardCarousal';
import { Settings } from 'react-slick';


export interface CardCarousalContainer_t {
    containerTitle?: string;
    subTitle?: string;
    cards: CarouselCardDetails_t[];
    carouselSettings?: Settings;
    footerButtonHandleClick?: (id: string) => void;
}

export default function CardCarousalContainer({cards, containerTitle, subTitle, carouselSettings,
    footerButtonHandleClick
}: CardCarousalContainer_t) {

    const imageHeight = useBreakpointValue({base: "75%", md: "60%" }, { ssr: false })
    if (carouselSettings) {
        return (<CardCarousal cards={cards} imageHeight={imageHeight!} carouselSettings={carouselSettings} footerButtonHandleClick={footerButtonHandleClick}
            containerTitle={containerTitle ? containerTitle : undefined} subTitle={subTitle ? subTitle : undefined}/>)
    }
    return (<CardCarousal cards={cards} imageHeight={imageHeight!} footerButtonHandleClick={footerButtonHandleClick}
    containerTitle={containerTitle ? containerTitle : undefined} subTitle={subTitle ? subTitle : undefined}/>)
}

