// theme.ts

// 1. import `extendTheme` function
import { extendTheme, ThemeConfig } from '@chakra-ui/react'

// 2. Add your color mode config
const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: true,
}

const breakpoints = {
  base: '0em', // 0px
  sm: '30em', // ~480px. em is a relative unit and is dependant on the font size.
  md: '48em', // ~768px
  lg: '68.75em', // ~992px
  xl: '80em', // ~1280px
  '2xl': '96em',
  '3xl': '125em',
}

// 3. extend the theme
const theme = extendTheme({
  config,
  styles: {
    global: () => ({
      body: {
        bg: "#f6f0e7"
      }
    })
  },
  breakpoints
})

export default theme