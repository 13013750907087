import { Box, Button, ButtonProps, Text } from "@chakra-ui/react";
import { primaryColor, secondaryColorTwo } from "../config";
import { PropsWithChildren } from "react";


export function ItalicText({ children, ...props }: PropsWithChildren<ButtonProps>) {
    return (<Box as='i' {...props}>
        {children}
    </Box>)
}

export function Author() {
    return (<Text color={secondaryColorTwo} textAlign={"right"}>~~~</Text>)
}