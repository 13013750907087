import { Box, Text, useDisclosure, Modal, ModalHeader, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, Button } from "@chakra-ui/react";
import { headingPrimaryFont, headingSecondaryFont, headingTertiaryFont, maxWidth, primaryColor, primaryFontSize, secondaryColorThree, secondaryColorTwo, secondaryFontSize, white } from "../../config";
import { ReactNode } from "react";


export interface ElevatedSideImageCardProps_t {
    img: any;
    imgAlt: string;
    title: string;
    subtitle: string;
    shortContent: string;
    content?: string;
    hoverTitle?: string;
    className?: string;
}

export default function ElevatedSideImageCard({ img, title, subtitle, shortContent, content, hoverTitle, className }: ElevatedSideImageCardProps_t) {

    const { isOpen, onToggle } = useDisclosure()

    return (
        <Box maxW={"70rem"}>
            <Box alignSelf={"center"}>
                <Box
                    maxH={{ base: "auto", lg: "25rem" }}
                    w={{ base: "auto", lg: "40rem" }}
                    maxW={{ base: "auto", lg: "50rem" }}
                    h={{ base: "auto", lg: "25rem" }}
                    minW={{ base: "auto", lg: "40rem" }}
                    title={content ? hoverTitle : undefined}
                    className={className + "_container"}
                >
                    <Box
                        h={{ base: "12rem", md: "15rem", lg: "18rem" }}
                        w={{ base: "12rem", md: "15rem", lg: "18rem" }}
                        ml={{ base: "1rem", md: "6rem", lg: "1rem" }}
                        position={"absolute"}
                        zIndex={1}
                        backgroundImage={img}
                        backgroundSize={{ base: "8rem", md: "10rem", lg: "12rem" }}
                        backgroundRepeat={"no-repeat"}
                        mt={{ base: "1rem", md: "4rem" }}
                        cursor={content ? "pointer" : "default"}
                        onClick={onToggle}
                    />

                    <Box
                        m={headingPrimaryFont}
                        mt={0}
                        display={{ base: "block", md: "flex" }}
                        maxW={{ base: "auto", lg: "35rem" }}
                        justifyContent={"center"}
                        cursor={content ? "pointer" : "default"}
                        onClick={onToggle}>
                        <Box
                            width={{ base: "60vw", md: "50vw", lg:"40rem" }}
                            minW={{ base: "65vw", md: "25rem" }}
                            minH={{ base: "60vh", md: "25rem" }}
                            paddingTop={{ base: headingSecondaryFont, mg: "0" }}
                            color={secondaryColorTwo}
                            bg={white}
                            pr={{ base: primaryFontSize }}
                            pb={{ base: "2rem" }}
                            position={"relative"}
                            mr = {"2rem"}
                            zIndex={0}
                            className={className + "_main-paper"}
                        >
                            <Box pt={{ base: "4rem" }}
                                ml={{ base: "6rem" }}
                                textAlign={{ base: "center" }}
                            >
                                <Text
                                    fontSize={headingTertiaryFont}
                                    color={"#6D6358"}

                                >{title}</Text>
                                <Box
                                    fontSize={primaryFontSize}
                                    color={"#6D6358"}
                                    as="a"
                                    href="/about"
                                    cursor={"pointer"}
                                >{subtitle}</Box>
                            </Box>
                            <Box
                                mt={{ base: "6rem", md: "3rem" }}
                                ml={{ base: "1rem", md: "8rem", lg: "12rem" }}
                                maxH={{ base: "10rem", lg: "15rem" }}
                                overflow={"scroll"}
                                css={{
                                    '&::-webkit-scrollbar': {
                                        width: '0',
                                    }
                                }}
                            >
                                <Text textAlign={"justify"} color={"#6D6358"}>
                                    "{shortContent}"
                                </Text>
                                <br />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <br />

            </Box>
            {(content && isOpen) &&
                <Modal scrollBehavior="outside" isOpen={isOpen} onClose={() => onToggle()} >
                    <ModalOverlay />
                    <ModalContent
                        maxW={{ base: "auto", lg: "32rem" }}
                        maxH={{ base: "auto", lg: "115rem" }}
                        w={{ base: "auto" }}
                        h={{ base: "auto" }}
                        minW={{ base: "auto", lg: "45rem" }}
                        overflow="scroll" bg={white}
                        color={secondaryColorThree}
                        fontSize={secondaryFontSize}
                        css={{
                            '&::-webkit-scrollbar': {
                                width: '0',
                            }
                        }}
                    >
                        <ModalHeader fontWeight={"0"} pb={0}>
                            <Box>
                                <Text fontSize={headingSecondaryFont} color={"#2c43f5"} textAlign={"center"}>#MyInnerEngineering Experience</Text>
                            </Box>
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody m={0} p={0}>


                            <Box maxW={"70rem"} >
                                <br />
                                <Box
                                    maxW={{ base: "auto", lg: "32rem" }}
                                    maxH={{ base: "auto", lg: "115rem" }}
                                    w={{ base: "auto", lg: "45rem" }}
                                    // h={{ base: "auto", lg: "35rem" }}
                                    minW={{ base: "auto", lg: "45rem" }}
                                >
                                    <Box
                                        display={"flex"}
                                        alignItems={"center"}
                                        alignContent={"space-around"}
                                        justifyContent={"space-evenly"}
                                    >

                                        <Box
                                            h={{ base: "14rem", md: "12rem", lg: "18rem" }}
                                            w={{ base: "14rem", md: "12rem", lg: "18rem" }}
                                            backgroundImage={img}
                                            backgroundSize={{ base: "8rem", lg: "12rem" }}
                                            backgroundRepeat={"no-repeat"}
                                            backgroundPosition={{ base: "50%" }}
                                            mb={{ base: "1rem", lg: "1rem" }}
                                            mr={{ base: 0, lg: "2rem" }}
                                            ml={{ base: 0, lg: "2rem" }}

                                        />

                                        <Box pt={{ base: 0 }}
                                            pb={{ base: "2rem" }}
                                            textAlign={{ base: "center" }}
                                        >
                                            <Text
                                                fontSize={headingTertiaryFont}
                                                color={"#6D6358"}

                                            >{title}</Text>
                                            <Box
                                                fontSize={primaryFontSize}
                                                color={"#6D6358"}
                                                as="a"
                                                href="/about"
                                                cursor={content ? "pointer" : "default"}
                                            >{subtitle}</Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <br />
                            </Box>
                            <Box
                                m={headingPrimaryFont}
                                mt={0}
                                // display={{ base: "block", md: "flex" }}
                                maxW={{ base: "auto", lg: "35rem" }}
                                justifyContent={"center"}>
                                <Box
                                    width={{ base: "80vw", md: "40rem" }}
                                    minW={{ base: "65vw", md: "25rem" }}
                                    paddingTop={{ base: headingSecondaryFont, mg: "0" }}
                                    color={secondaryColorTwo}
                                    bg={white}
                                    pr={{ base: primaryFontSize }}
                                >


                                    <Text textAlign={"justify"} color={"#6D6358"}>
                                        As i anguished the strangling of the subjective boundries of the limited perception, Inner Enggineering came like a natural phenomenon,
                                        akin to a season for the flowers to  bloom.
                                    </Text>

                                    <Text textAlign={"justify"} color={"#6D6358"}>
                                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                        Though i knew it was rope in the dark, the fear of snake remained until the light was dawned on it.
                                    </Text>
                                    <br />
                                    <Text textAlign={"justify"} color={"#6D6358"}>
                                        Though it is obvious that the life stems from the objective, progressing through the subjective and ultimately culminating where it all began,
                                        the mind struggled to come to terms with it being torn apart by the subjective boundries; Inner Enggineering is a way of liting a lamp to see that
                                        its not life and death but birth, living and death are nothing but a phases of life;
                                        its not consciousness and perception but clarity, stability and perception are the phases of consciousness.
                                    </Text>
                                </Box>
                            </Box>
                            <ModalFooter justifyContent={"center"}>
                                <Button bg={primaryColor} color={"white"}
                                    onClick={() => onToggle()}
                                    _hover={{
                                        backgroundColor: "#2c43f5",
                                    }}>Close</Button>
                            </ModalFooter>
                        </ModalBody>
                    </ModalContent>

                </Modal>}
        </Box>)
}