import { Box, Text, useBreakpointValue } from "@chakra-ui/react";
import { bodyBGColor, headingPrimaryFont, headingSecondaryFont, primaryColor } from "../config";
import { libraryCards } from "../data/data";
import Post from "./ModalPost";
import CarouselCardPost from "./Carousels/CarouselCardPost";
import DividerAbsoluteCenter from "./DividerAbsoluteCenter";
import { useEffect, useState } from "react";
import { CarouselCardDetails_t } from "./Carousels/CarouselCard";
import { useNavigate } from "react-router-dom";

export default function LibraryBlog() {
    const urlParam = new URLSearchParams(window.location.search);
    const imageHeight = useBreakpointValue({ base: "15rem", md: "20rem" }, { ssr: false })
    const [isOpen, setIsOpen] = useState(true);
    const navigate = useNavigate();
    function onClose() {
        setIsOpen(false)
        navigate(`/blog#${libraryCards[0].groupId}`)
    }

    const [modelPost, setModelPost] = useState<CarouselCardDetails_t | undefined>(undefined);

    useEffect(() => {
        if (urlParam.get("id") && !modelPost) {
            const redirectedPost = [...libraryCards].filter(card => card.groupId + "_" + card.id == urlParam.get("id"))
            redirectedPost.length === 1 ? setModelPost(redirectedPost[0]) : setModelPost(undefined)
        }
    }, [modelPost])

    function handleClick(id: string) {
        setIsOpen(true)
        const selectedPost = libraryCards.filter(card => card.groupId+"_"+card.id == id)
        setModelPost(selectedPost.length == 1 ? selectedPost[0] : undefined)
    }

    return (
        <Box id="library">
            <DividerAbsoluteCenter bg={bodyBGColor} dividerBorderColor={primaryColor} >
                <Text fontSize={{ base: headingSecondaryFont, lg: headingPrimaryFont }} textAlign={"center"}
                    color={primaryColor}
                >Library</Text>
            </DividerAbsoluteCenter>
            <Box >
                <br />
                <CarouselCardPost card={libraryCards[0]} imageHeight={imageHeight!} footerButtonHandleClick={handleClick} />
                {modelPost
                    && <Post card={modelPost}
                        isOpen={isOpen}
                        onClose={onClose}
                    />}
            </Box>
        </Box>
    )
}