'use client'

import {
    Box,
    Flex,
    Text,
    Stack,
    Icon,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    PopoverArrow,
    Link,
} from '@chakra-ui/react'
import {
    ChevronRightIcon,
} from '@chakra-ui/icons'
import { NavItem, NavProps_t } from './NavBarTypes.ts/NavItem'
import { headingSecondaryFont, primaryFontSize, secondaryColorOne, secondaryColorTwo, secondaryFontSize } from '../../config'


export default function DesktopNavBar({ navItems }: NavProps_t) {
    const linkColor = useColorModeValue('gray.600', 'gray.200')
    const linkHoverColor = useColorModeValue('gray.800', 'white')
    const popoverContentBgColor = useColorModeValue('white', 'gray.800')

    return (
        <Stack direction={'row'} >
            {navItems.map((navItem) => (
                <Box display={"flex"} width={"7rem"} justifyContent={"space-around"} alignItems={"center"} key={navItem.label}>
                    <Box key={navItem.label}
                        rounded={'xl'}
                        h={{ lg:"2.5rem"}}
                        alignContent={"center"}
                        _hover={{
                            backgroundColor: secondaryColorTwo,
                        }}>
                        <Popover trigger={'hover'} placement={'bottom-start'} >
                            <PopoverTrigger>
                                <Link
                                    p={2}
                                    minW={"4rem"}
                                    href={navItem.href ?? '#'}
                                    fontSize={secondaryFontSize}
                                    fontWeight={500}
                                    color={'white'}
                                    _hover={{
                                        textDecoration: 'none',
                                        color: "white",
                                    }}>
                                    {navItem.label}
                                </Link>
                            </PopoverTrigger>
                            <PopoverArrow />

                            {navItem.children && (
                                <PopoverContent
                                    border={0}
                                    boxShadow={'xl'}
                                    bg={popoverContentBgColor}
                                    p={4}
                                    rounded={'xl'}>
                                    <PopoverArrow />
                                    <Stack>
                                        {navItem.children.map((child) => (
                                            <DesktopSubNav key={child.label} {...child} />
                                        ))}
                                    </Stack>
                                </PopoverContent>
                            )}
                        </Popover>
                    </Box>
                    <Box fontSize={headingSecondaryFont} color={secondaryColorTwo}>|</Box>
                </Box>

            ))}
        </Stack>
    )
}

const DesktopSubNav = ({ label, href, subLabel, target }: NavItem) => {
    return (
        <Box
            as="a"
            href={href}
            role={'group'}
            display={'block'}
            target={target ?? ''}
            p={2}
            rounded={'md'}
        >
            <Stack direction={'row'} align={'center'}>
                <Box>
                    <Text
                        transition={'all .3s ease'}
                        _groupHover={{ color: secondaryColorOne }}
                        fontWeight={500}>
                        {label}
                    </Text>
                    <Text fontSize={primaryFontSize}>{subLabel}</Text>
                </Box>
                <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}>
                    <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
                </Flex>
            </Stack>
        </Box>
    )
}