import {
    Box,
    Text,
    Stack,
    Collapse,
    Icon,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react'
import { 
    ChevronDownIcon,
} from '@chakra-ui/icons'
import { NavItem, NavProps_t } from './NavBarTypes.ts/NavItem'

export default function MobileNavBar({navItems} : NavProps_t) {
    return (
        <Stack bg={useColorModeValue('white', 'gray.800')} p={4} display={{ lg: 'none' }} >
            {navItems.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}
        </Stack>
    )
}

const MobileNavItem = ({ label, children, href }: NavItem) => {
    const { isOpen, onToggle, onClose, onOpen } = useDisclosure()

    return (
        <Stack spacing={4} onClick={children && onToggle} onMouseEnter = { onOpen }onMouseLeave = { onClose } >
            <Box
                py={2}
                as="a"
                cursor={"pointer"}
                href={href ?? '#'}
                justifyContent="space-between"
                alignItems="center"
                _hover={{
                    textDecoration: 'none',
                }}
                display={'flex'}
                
            >
                <Text fontWeight={600} color={useColorModeValue('gray.600', 'gray.200')}>
                    {label}
                </Text>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={'all .25s ease-in-out'}
                        transform={isOpen ? 'rotate(180deg)' : ''}
                        w={6}
                        h={6}
                    />
                )}
            </Box>

            {/* transition={{ exit: { duration: 0.5 }, enter: { duration: 0.5 } }} */}
            <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}  >
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}
                    >
                    {children &&
                        children.map((child) => (
                            <Box as="a" key={child.label} py={2} href={child.href}
                            target={child.target ?? ''}>
                                {child.label}
                                <br></br>
                                {child.subLabel}
                            </Box>
                        ))}
                </Stack>
            </Collapse>
        </Stack>
    )
}