import { Box, Text } from "@chakra-ui/react";
import Carousel from "./Carousel";
import { useBreakpointValue } from '@chakra-ui/react'
import CarouselCard, { CarouselCardDetails_t } from "./CarouselCard";
import { Settings } from "react-slick";
import { bodyBGColor, headingPrimaryFont, headingSecondaryFont, maxWidth, primaryColor, primaryFontSize, secondaryColorTwo, secondaryFontSize } from "../../config";
import { CarouselcontainerStyles_t } from "./carousalTypes";
import DividerAbsoluteCenter from "../DividerAbsoluteCenter";
import ElevatedSideImageCard, { ElevatedSideImageCardProps_t } from "../ElevatedSideImageCard/ElevatedSideImageCard";

export interface CardCarousalProps_t {
    elevatedCards: ElevatedSideImageCardProps_t[];
    // imageHeight: string;
    containerTitle?: string;
    subTitle?: string;
    carouselSettings?: Settings;
}

export default function ElevatedCardCarousal({ elevatedCards, containerTitle, subTitle,
    carouselSettings = {
        fade: false,
        slidesToShow: elevatedCards.length > 2 ? 3 : elevatedCards.length,
        slidesToScroll: 1,
        arrows: elevatedCards.length > 2,
        responsive: [
            {
                breakpoint: 3000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: elevatedCards.length > 2,
                    // autoplay: elevatedCards.length > 2 ,
                    autoplaySpeed: 5000,
                    dots: elevatedCards.length > 2 ,
                }
            },
            {
                breakpoint: 1900,
                settings: {
                    slidesToShow: elevatedCards.length > 2 ? 2 : elevatedCards.length,
                    slidesToScroll: 1,
                    infinite: elevatedCards.length > 2,
                    // autoplay: elevatedCards.length > 2 ,
                    autoplaySpeed: 5000,
                    dots: elevatedCards.length > 2 ,
                }
            },
            {
                breakpoint: 1320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: elevatedCards.length > 1,
                    dots: elevatedCards.length > 2 ,
                    autoplay: elevatedCards.length > 2 ,
                    autoplaySpeed: 5000,
                    arrows: elevatedCards.length > 1,
                }
            },
        ]
    } }: CardCarousalProps_t) {
    const carouselItems = elevatedCards.map(card => (
        <Box maxW={"90rem"} pl={{"3xl": "20rem"}} key={card.title}>
            <ElevatedSideImageCard  img={card.img} title={card.title} subtitle={card.subtitle}
            imgAlt={card.imgAlt} hoverTitle={card.hoverTitle}
            shortContent={card.shortContent} content={card.content} 
            className="elevated-card"/>
        </Box>
    ))

    const arrows = useBreakpointValue({ ssr: false, base: true, lg: false })
    if (!carouselSettings.arrows) {
        carouselSettings.arrows = arrows
    }

    const carouselContainerStyle: CarouselcontainerStyles_t = {
        position: useBreakpointValue({ base: "relative", lg: "static" })!,
        height: useBreakpointValue({ base: "30rem" })!,
        minHeight: useBreakpointValue({ base: "auto" })!,
        maxHeight: useBreakpointValue({ base: "auto" })!,
        width: useBreakpointValue({ base: "90vw" })!,
        overflow: 'hidden',
        zIndex: 0
    }

    return (
        <Box
            maxW={{ base: "100vW", "lg": "95rem" }}
        >
            {containerTitle &&
                (<DividerAbsoluteCenter bg={bodyBGColor} dividerBorderColor={primaryColor}>
                    <Text fontSize={headingPrimaryFont} color={primaryColor}>{containerTitle}</Text>
                </DividerAbsoluteCenter>)
            }
            {
                subTitle && <Box textAlign={"center"}>
                    <Text fontSize={secondaryFontSize} color={secondaryColorTwo}>{subTitle}</Text>
                </Box>
            }
            <br />
            <Carousel cards={carouselItems} carouselSettings={carouselSettings} 
            sliderArrowLeftTop={useBreakpointValue({base:"60%", md:"5%", lg:"0"})}
            sliderArrowLeftSide={useBreakpointValue({base:"", md:"80%", "2xl": "70rem"})}
            sliderArrowRightTop={useBreakpointValue({base:"60%", md:"5%", lg:"0"})}
            sliderArrowRightSide={useBreakpointValue({base:"", md:"8%", "2xl": "10rem"})}
                containerStyles={carouselContainerStyle} cauouselContainerClassName="eleveated-card"
            />
            {/* {carouselItems} */}
        </Box>)
}