import ResponsiveNavBarContainer from "../components/NavBar/ResponsiveNavBarContainer";
import IntroductionWithImage from "../components/IntroductionWithImage";
import MainImageCarousel from "../components/MainImageCarousel";
import CardCarousalContainer from "../components/CardCarousalContainer";
import StickyNavAndFooter from "./layout/StickyNavAndFooter";
import { libraryPageIntroduction, missionCards, schoolPageIntroduction, visionCards } from "../data/data";
import { Heading, Text } from "@chakra-ui/react";
import blossomintoinnerfreedom from '../resources/images/blossomintoinnerfreedom.png'

export default function SoftwareSystems() {
    return (
        <StickyNavAndFooter>
            <ResponsiveNavBarContainer />
            <MainImageCarousel />
            <IntroductionWithImage img={blossomintoinnerfreedom}> 
                {libraryPageIntroduction}
            </IntroductionWithImage>
            <Heading>Introduction</Heading>
            {/* <CardCarousalContainer cards={visionCards} containerTitle={"Vision"}/>
            <br />
            <br />
            <CardCarousalContainer cards={missionCards} containerTitle="Mission"/> */}
        </StickyNavAndFooter>
    )
}