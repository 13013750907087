import { Box, useBreakpointValue } from "@chakra-ui/react"
import Carousel from "./Carousels/Carousel";
import { CarouselcontainerStyles_t } from "./Carousels/carousalTypes";

export default function MainImageCarousel() {
    const imageList = [
        {
            url: 'https://images.unsplash.com/photo-1612852098516-55d01c75769a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDR8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60',
            alt: ""
        },
        {
            url: 'https://images.unsplash.com/photo-1627875764093-315831ac12f7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDJ8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60',
            alt: ""
        },
        {
            url: 'https://images.unsplash.com/photo-1571432248690-7fd6980a1ae2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDl8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60',
            alt: ""
        },
    ]

    const carouselContainerStyle: CarouselcontainerStyles_t = {
        position: "relative",
        height: useBreakpointValue({ base: "40vh", md: "70vh" })!,
        minHeight: useBreakpointValue({ base: "15rem", md: "40rem" })!,
        maxHeight: useBreakpointValue({ base: "50vh", md: "70vh" })!,
        width: 'full',
        overflow: 'hidden',
        zIndex: 0
    }

    const boxtToBeRenderedH = useBreakpointValue({ base: "15rem", md: "35rem" })
    const boxtToBeRenderedMinH = useBreakpointValue({ base: "10rem", md: "38rem" })
    const boxtToBeRenderedMaxH = useBreakpointValue({ base: "40vh", md: "50vH" })

    const boxtToBeRendered = imageList.map((image, index) => (
        <Box
            key={index}
            h = {boxtToBeRenderedH}
            minH={boxtToBeRenderedMinH}
            maxH={boxtToBeRenderedMaxH}
            position="relative"
            overflow={"hidden"}
        >
            <img src={image.url} alt={image.alt} width={"100%"} object-fit="cover" />
        </Box>
    ));


    return (<>
        <Carousel cards={boxtToBeRendered} containerStyles={carouselContainerStyle} />
    </>)
}


