export const maxWidth = "100rem"
export const primaryColor =  "#7b3c2d"
export const secondaryColorOne = "green.400"
export const secondaryColorTwo =  "green.600"
export const secondaryColorThree =  "green.800"

export const white = "white"


export const headingPrimaryFont = "3rem"
export const headingSecondaryFont = "2rem"
export const headingTertiaryFont = "1.5rem"


export const smallFontSize = "0.5rem"
export const primaryFontSize = "1rem"
export const secondaryFontSize = "1.2rem"

export const bodyBGColor = "#f6f0e7"
