import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./styles.css";
import Home from "./pages/Home";
import About from "./pages/About";
import BlogPage from "./pages/BlogPage";
import HomeSchool from "./pages/HomeSchool";
import Library from "./pages/Library";
import SoftwareSystems from "./pages/SoftwareSystems";
import ExperiencesPage from "./pages/ExperiencesPage";


const router = createBrowserRouter([
  {'path': '/', element: <Home />},
  {'path': '/home', element: <Home />},
  {'path': '/about', element: <About />},
  {'path': '/blog', element: <BlogPage />},
  {'path': '/homeschool', element: <HomeSchool />},
  {'path': '/library', element: <Library />},
  {'path': '/softwaresystems', element: <SoftwareSystems />},  
  {'path': '/our-experiences', element: <ExperiencesPage />}  
])

export default function App() {


  return (<RouterProvider router={router}/>
  );
}
