import React from 'react'
import { Box } from '@chakra-ui/react'
import Slider from 'react-slick'
import { RightCarouselArrow, LeftCarouselArrow } from './CarouselArrows'
import { CarouselsProps_t } from './carousalTypes'
import { maxWidth } from '../../config'



export default function Carousel({ cards,
    carouselSettings = {
        fade: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 50,
        adaptiveHeight: true,
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    }, containerStyles,
    sliderArrowLeftSide,
    sliderArrowLeftTop,
    sliderArrowRightSide,
    sliderArrowRightTop, cauouselContainerClassName = ""
}: CarouselsProps_t) {
    // As we have used custom buttons, we need a reference variable to
    // change the state
    const [slider, setSlider] = React.useState<Slider | null>(null)

    // These are the breakpoints which changes the position of the
    // buttons as the screen size changes
    const leftTop = sliderArrowLeftTop ? sliderArrowLeftTop : { base: '50%', md: '50%' }
    const leftSide = sliderArrowLeftSide ? sliderArrowLeftSide : { base: '10px', md: '10px' }
    const rightTop = sliderArrowRightTop ? sliderArrowRightTop : { base: '50%', md: '50%' }
    const rightSide = sliderArrowRightSide ? sliderArrowRightSide : { base: '10px', md: '10px' }

    // const infinite = cards.length >  3;
    // Settings for the slider
    const settings = {
        dots: carouselSettings.dots,
        arrows: carouselSettings.arrows,
        fade: carouselSettings.fade,
        slidesToShow: carouselSettings.slidesToShow,
        infinite: carouselSettings.infinite,
        autoplay: carouselSettings.autoplay,
        speed: carouselSettings.speed,
        autoplaySpeed: carouselSettings.autoplaySpeed,
        adaptiveHeight: carouselSettings.adaptiveHeight,
        responsive: carouselSettings.responsive,
        nextArrow: <RightCarouselArrow arrowClassName={cauouselContainerClassName + "_slider-arrow-right"} slider={slider} top={rightTop} side={rightSide} />,
        prevArrow: <LeftCarouselArrow arrowClassName={cauouselContainerClassName + "_slider-arrow-left"} slider={slider} top={leftTop} side={leftSide} />,

        // https://chakra-templates.vercel.app/page-sections/carousels
        // https://react-slick.neostack.com/docs/example/adaptive-height
        // https://stackoverflow.com/questions/67493795/modify-css-on-react-slick
        // https://codesandbox.io/p/sandbox/custom-dots-react-slick-pl27t?file=%2Fsrc%2F_SlickSliderStyle.js%3A4%2C16
    }

    return (
        <Box position={containerStyles.position}
            height={containerStyles.height}
            minHeight={containerStyles.minHeight}
            maxW={maxWidth}
            width={containerStyles.width}
            overflow={containerStyles.overflow}
            zIndex={containerStyles.zIndex}
            className={cauouselContainerClassName + "_slider-container"}
        >
            {/* CSS files for react-slick */}
            <link
                rel="stylesheet"
                type="text/css"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
            />
            <link
                rel="stylesheet"
                type="text/css"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
            />
            <Slider {...settings} ref={(slider) => setSlider(slider)}>
                {
                    cards.map((card: any) => card)
                }
            </Slider>
        </Box>
    )
}
