import { Box, Text, Image, Heading, Collapse, useDisclosure, Modal, Button, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/react";
import me from '../resources/images/me.png'
import { GenericTableContainer } from "./GenericTableContainer";
import { headingPrimaryFont, maxWidth, primaryColor, secondaryColorThree, secondaryColorTwo, headingTertiaryFont, headingSecondaryFont, secondaryFontSize, bodyBGColor, primaryFontSize } from "../config";
export default function SelfIntroduction() {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const header = ["Time", "Tasks?"]
    // change for mobile view
    const body = [
        ["1 - 4", `Don't Remember, Didn't happen? ${String.fromCodePoint(parseInt("0x1F937", 16))}`],
        ["4 - 16", `Schooling - Self Loathing; Picking up Pieces? ${String.fromCodePoint(parseInt("0x1F644", 16))}`],
        ["16 - 19", `Diploma in ECE - Life? ${String.fromCodePoint(parseInt("0x1F914", 16))}`],
        ["20 - 22", `BTECH in ECE @ RVCE - Trap?! ${String.fromCodePoint(parseInt("0x1F630", 16))}`],
        ["23 - 24", `MIT @ UOA - Twilight ${String.fromCodePoint(parseInt("0x1F60C", 16))}`],
        ["25 - 27", `SE @ Orion Health - The Beginning! ${String.fromCodePoint(parseInt("0x1F642", 16))}`]]

    function formatRow(row: string[], rowKey: number) {
        return row.map(cell => <Text textAlign={"center"} key={rowKey}>{cell}</Text>)
    }

    return (
        <>
            <Box
                maxW={{ base: "auto", lg: maxWidth }}
                m={headingSecondaryFont}
                mt={0}
                display={{ base: "block", "lg": "flex" }}
                justifyContent={"center"}>
                <Box maxW={"70rem"}>
                    <Box>
                        <Text fontSize={headingPrimaryFont} color={secondaryColorTwo}>Sharath?</Text>
                    </Box>

                    <Box
                        mt={0}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        flexDirection={{ base: "column", md: "row" }}>

                        <Box width={{ base: "100%", md: "20vw" }}
                            height={"25rem"}
                            minH={"25rem"}
                            minW={"10rem"}
                            mr={{ base: 0, md: "4rem" }}
                        >
                            <Image src={me} objectFit="contain" height={"100%"} />
                        </Box>

                        <Box
                            width={{ base: "90vw", md: "50vw" }}
                            minW={{ base: "50vw", md: "25rem" }}
                            paddingTop={{ base: headingSecondaryFont, mg: "0" }}
                            flex={1}
                            color={secondaryColorTwo}
                        >
                            <Text fontSize={headingTertiaryFont} color={primaryColor}>What Have I done?</Text>
                            <Box display="flex" >
                                <GenericTableContainer dataHeader={header} dataBody={body} formatRow={formatRow} highlightRow={0} />
                            </Box>
                        </Box>
                    </Box>
                    <br />
                    <Box >
                        <Heading fontSize={headingTertiaryFont} color={primaryColor}>What am I upto?</Heading>
                        <br />
                        <Text textAlign={"justify"} color={secondaryColorThree} fontSize={secondaryFontSize}>
                            I have always grappled with questions, one or the other, of all kinds, relevant, irrelevant,
                            sane, stupid, and some were answered, some are lingering in the corner of mind
                            waiting for their time while some are forgotten, but each and every one of them have taught
                            me or rather made me realize the fact that someone else can’t answer my questions for me;
                            It’s only “I” can to myself, but not without the people, life around me.
                        </Text>
                        <Text cursor={"pointer"} color={"blue"} onClick={onOpen} textAlign={"right"}>show more...</Text>
                    </Box>
                </Box>
            </Box>

            <Modal scrollBehavior="outside" isOpen={isOpen} onClose={() => onClose()} size={"full"} >
                <ModalOverlay />
                <ModalContent
                    maxH={{ base: "full", md: "80vh" }}
                    maxW={{ base: "full", md: "70vw" }}
                    overflow="scroll" bg={bodyBGColor}
                    color={secondaryColorThree}
                    fontSize={secondaryFontSize}>
                    <ModalHeader fontWeight={"0"}>
                        <Box>
                            <Text fontSize={headingPrimaryFont} color={primaryColor} textAlign={"center"}>Hello!</Text>
                        </Box>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody m={0} p={0} >
                        <Box
                            m={{ base: 0, md: headingSecondaryFont }}
                            mt={0} pt={0}
                            display={{ base: "block", "md": "flex" }}
                            flexDir={"column"}
                            pr={{ base: headingSecondaryFont, lg: headingPrimaryFont }}
                            pl={{ base: headingSecondaryFont, lg: headingPrimaryFont }}
                            justifyContent={"center"}>
                            <Box
                                display={{ base: "block", "md": "flex" }}
                                justifyContent={"space-around"}
                            >
                                <Box width={{ base: "100%", md: "90vw" }}
                                    height={"25rem"}
                                    minH={"25rem"}
                                    minW={"10rem"}
                                    mr={{ base: 0, md: "4rem" }}
                                    mb = {{base: headingPrimaryFont, md:0}} 
                                >
                                    <Image objectFit="contain" src={me} alt={"me"} height={"100%"} width={"100%"} object-fit="cover" />
                                </Box>
                                <Text textAlign={"justify"} color={secondaryColorThree} fontSize={secondaryFontSize} >
                                    
                                    <Box textAlign="center" fontSize={headingTertiaryFont} color={secondaryColorTwo}>
                                        What am I upto?
                                    </Box>
                                    <br />
                                    I have always grappled with questions, one or the other, of all kinds, relevant, irrelevant,
                                    sane, stupid, and some were answered, some are lingering in the corner of mind
                                    waiting for their time while some are forgotten, but each and every one of them have taught
                                    me or rather made me realize the fact that someone else can’t answer my questions for me;
                                    It’s only “I” can to myself, but not without the people, life around me.
                                    <br />
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    Like a seed that can only grow by itself but not without the soil, water, sun, and the suitable weather or the ambiance.
                                    <br /><br />
                                    Each individual is a flower, a unique one in that! The growth is of a different nature while the bloom is of a different
                                    nature whole together; how many species does our earth accommodate? Countless indeed!
                                </Text>
                            </Box>
                            <Box >
                                <Text textAlign="justify" fontSize={secondaryFontSize}>
                                    <br />
                                    The beautiful flower that only blooms for the moon, flower that blooms once in a century
                                    or decade, in moderate environment, extreme conditions of sorts, flowers with beauty, vibrancy and thorns, at the same
                                    time, stinky, fragrant, poisonous flowers that heals, kills, and a flower that has become the symbol of purity while in
                                    mud! Is there an end to it?
                                    <br />
                                    <br />
                                    Our earth is my inspiration! If she’s doing it why can’t we? And with that, this is an earnest attempt in bringing together
                                    our community, friends and family, kutumbam, vasudhivya kutumbakam, in the spirit of ‘Sanatána Dharma’ to create the ambiance,
                                    situations necessary for individuals to bloom to their fullest; only which can reverse the direction we are heading towards.
                                    The direction of apathy, indifference, superficiality and ignorance of one’s own nature. One’s nature can only be discovered
                                    individually by their one way of deep enquiry and questioning.
                                    <br />
                                    <br />
                                    In my experience, beyond success, failure, good, bad and norms of the society that are instigating helplessness, mental illness
                                    like never before causing humans to commit suicide at a prolific rate; there are questions related to everything, everyone and
                                    simply can’t rid myself off! They have always been there, how much ever the crowd tried to bury them they have been surfaced by
                                    the time again and again!
                                    <br />
                                    <br />
                                    <Box textAlign={"center"}>
                                        {/* The questions <br /> */}
                                        what is life? <br />
                                        What is Death? <br />
                                        And Who am I? <br />
                                    </Box>
                                    <br />
                                    Iornically these are the questions shunned in ignorance but are revered and hailed as the harbinger of awareness and consciousness of
                                    truth untouched by time; in this spirit of enquiry it's an earnest attempt to revive the symbiotic environment of life, the only
                                    way demonstrated by our earth, to find the ultimate well being, to grow, bloom and find fulfillment,
                                    <Box textAlign={"center"}>
                                        Let’s grow being step to one another! <br /> <br />
                                        World full of Love, Light and Laughter; <br />
                                        It’s time has come, Let’s make it happen. <br />
                                        <Box textAlign={"center"}>
                                            - Sadhguru <br /> <br />
                                            Shivoam Shivoam Shivoam
                                        </Box>
                                    </Box>
                                </Text>
                            </Box>
                            <Text color={secondaryColorTwo} textAlign={"right"}>~~~</Text>
                        </Box>

                        <ModalFooter justifyContent={"center"}>
                            <Button bg={primaryColor} color={"white"}
                                onClick={() => onClose()}
                                _hover={{
                                    backgroundColor: secondaryColorTwo,
                                }}>Close</Button>
                        </ModalFooter>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>)
}