import {
    Box, Text, Modal, ModalOverlay, ModalContent, ModalHeader,
    ModalCloseButton, ModalBody, ModalFooter, Button, IconButton, Tooltip
} from "@chakra-ui/react";
import { headingPrimaryFont, maxWidth, primaryColor, secondaryColorTwo, headingTertiaryFont, headingSecondaryFont, white, secondaryFontSize, bodyBGColor, primaryFontSize } from "../config";
import { CarouselCardDetails_t } from "./Carousels/CarouselCard";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";

export interface PostProps_t {
    card: CarouselCardDetails_t;
    onClose: Function;
    isOpen: boolean;
    closeButtonHref?: string;
}


export default function ModalPost({ card, onClose, isOpen, closeButtonHref }: PostProps_t) {
    return (<>
        <Modal scrollBehavior="outside" isOpen={isOpen} onClose={() => onClose()} size={"full"} >
            <ModalOverlay />
            <ModalContent maxH={{ base: "full", md: "80vh" }} maxW={{ base: "full", md: "70vw" }} overflow="scroll" bg={bodyBGColor}>
                <ModalHeader fontWeight={"0"}>
                    <Box>
                        <Text fontSize={headingPrimaryFont} color={primaryColor} textAlign={"center"}>{card.title}</Text>
                    </Box>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody m={0} p={0} >
                    <Box
                        m={{ base: 0, md: headingSecondaryFont }}
                        mt={0} pt={0}
                        display={{ base: "block", "md": "flex" }}
                        justifyContent={"center"}>
                        <Box
                            pr={{ base: headingSecondaryFont, lg: headingPrimaryFont }}
                            pl={{ base: headingSecondaryFont, lg: headingPrimaryFont }}
                        >
                            <Box height={{ base: "15rem", md: "30rem" }} width={"full"} overflow={"hidden"}
                                backgroundImage={card.image}
                                backgroundSize={{base:"15rem", md:"30rem"}}
                                bgPos={"50%"}
                                backgroundRepeat={"no-repeat"}
                                textAlign={"center"}
                            />
                               
                            
                            <br />
                            <Box >
                                <Text textAlign="center" fontSize={headingTertiaryFont} color={secondaryColorTwo}>
                                    {card.body.title}
                                </Text>
                                <br />
                                <Box textAlign="justify" fontSize={secondaryFontSize} color={"black"}>
                                    {card.body.content}
                                </Box>
                            </Box>
                            <br />
                            <br />
                            <Text color={secondaryColorTwo} textAlign={"right"}>~~~</Text>
                        </Box>
                    </Box>
                </ModalBody>

                <ModalFooter justifyContent={"center"}>
                    <Box display={"flex"} m="1rem" justifyContent={"space-between"}>

                        <Tooltip label={card.previous?.title}>
                            <IconButton
                                mr={{ base: primaryFontSize, md: headingPrimaryFont }}
                                aria-label="left-arrow"
                                colorScheme="messenger"
                                borderRadius="full"
                                bgColor={primaryColor}
                                color={white}
                                as="a"
                                disabled={!card.previous}
                                href={card.previous?.href}
                                _hover={{
                                    backgroundColor: card.previous ? secondaryColorTwo : "grey",
                                }}
                            >
                                <BiLeftArrow />
                            </IconButton>
                        </Tooltip>
                        {
                            closeButtonHref ? <Button bg={primaryColor} color={"white"} as="a" href={closeButtonHref}
                                onClick={() => onClose()}
                                _hover={{
                                    backgroundColor: secondaryColorTwo,
                                }}>Close</Button> :
                                <Button bg={primaryColor} color={"white"}
                                    onClick={() => onClose()}
                                    _hover={{
                                        backgroundColor: secondaryColorTwo,
                                    }}>Close</Button>
                        }



                        <Tooltip label={card.next?.title}>
                            <IconButton ml={{ base: primaryFontSize, md: headingPrimaryFont }}
                                aria-label="left-arrow"
                                bgColor={primaryColor}
                                color={white}
                                borderRadius="full"
                                _hover={{
                                    backgroundColor: card.next ? secondaryColorTwo : "grey",
                                }}
                                as="a"
                                disabled={!card.next}
                                href={card.next?.href}
                            >
                                <BiRightArrow />
                            </IconButton>
                        </Tooltip>

                    </Box>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>
    )
}