
import { PropsWithChildren } from 'react'
import { Box } from "@chakra-ui/react";
import { maxWidth} from "../../config";
import ResponsiveNavBarContainer from '../../components/NavBar/ResponsiveNavBarContainer';
import ResponsiveFooter from '../../components/Footer/ResponsiveFooter';


export default function NonStickyNavAndFooter({ children }: PropsWithChildren) {
    return (
        <>
            <Box className="nav-bar" position={"absolute"} minHeight={"5rem"}
                height={"10vh"} w="100vw" />
            <Box maxW={maxWidth}
                position={"relative"}
                margin={"auto"} >
                <ResponsiveNavBarContainer sticky={false} />
                {children}
                <br />
                <br />
            </Box>
            <ResponsiveFooter/> 
        </>
    )
}