import React from 'react'
import { IconButton } from '@chakra-ui/react'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'

export function LeftCarouselArrow({ side, top, slider, arrowClassName }: any) {
    return (<IconButton
        className={arrowClassName}
        aria-label="left-arrow"
        colorScheme="messenger"
        borderRadius="full"
        position="absolute"
        left={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => slider?.slickPrev()}>
        <BiLeftArrowAlt />
    </IconButton>)
}


export function RightCarouselArrow({ side, top, slider, arrowClassName }: any) {
    return <IconButton
        className={arrowClassName}
        aria-label="right-arrow"
        colorScheme="messenger"
        borderRadius="full"
        position="absolute"
        right={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => slider?.slickNext()}>
        <BiRightArrowAlt />
    </IconButton>
}

export default { LeftCarouselArrow, RightCarouselArrow }